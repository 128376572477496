import React from 'react';
import getStyles from './styles';
import SbEditable from "storyblok-react";

export default function Legal(props) {
  const id = 'legal';
  const { className, styles } = getStyles();

  return (
    <SbEditable content={props.blok}>
    <section id={ `${id}` } className={ className }>
      <div id={ `${id}-copy` } className={ className } dangerouslySetInnerHTML={ { __html: props.storyblokClient.richTextResolver.render(props.blok.copy)} } />
      { styles }
    </section>
    </SbEditable>
  );
}
