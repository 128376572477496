import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
    main {
      flex: 0 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start;
      box-sizing: border-box;
      margin: 0 auto var(--safe-area-inset-bottom);
      width: 100%;
      background-color: #FFFFFF;
      flex-grow: 1;
    }
    
    header {
      position: sticky;
      top: 0;
      z-index: 1;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      box-sizing: border-box;
      width: 100%;
      
      > div {
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        box-sizing: border-box;
        width: 100%;
        height: 100px;
        background-color: rgb(0, 43, 57);
        padding: 24px;
        justify-content: space-between;
        &.with-nav {
          padding: 12px 24px 48px;
        }

        img {
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }

        nav {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          
          a {
            flex: 1;
            padding: 0 16px;
            color: white;
            text-decoration: none;
            text-align: center;
          }
        }

        #navi-cta-button {
          display: flex;
          align-items: center;
          align-content: center;
          height: 32px;
          border-radius: 16px;
          padding: 0 32px;
          background-color: rgb(255, 196, 37);
          color: rgb(0, 43, 57);
          justify-content: center;
          text-decoration: none;
        }
      }
    }

    @media only screen and (min-width: 980px) {
      header {
        margin: var(--safe-area-inset-top) auto 0;

        > div.with-nav {
          padding: 24px;

          nav {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            
            a {
              flex: 0 1 auto;
            }
          }
        }
      }
    }
  `;
};
