import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
    section {
      margin-bottom: 0;
    }

    iframe {
      width: 100%;
      margin: 0 auto;
      border: none;
    }

    @media only screen and (min-width: 258px) {
      iframe {
        height: 2120px;
      }
    }

    @media only screen and (min-width: 296px) {
      iframe {
        height: 2207px;
      }
    }

    @media only screen and (min-width: 414px) {
      iframe {
        height: 2211px;
      }
    }

    @media only screen and (min-width: 442px) {
      iframe {
        height: 2515px;
      }
    }

    @media only screen and (min-width: 528px) {
      iframe {
        height: 2277px;
      }
    }

    @media only screen and (min-width: 768px) {
      iframe {
        height: 1170px;
      }
    }

    @media only screen and (min-width: 980px) {
      iframe {
        display: flex;
        height: 1076px;
        width: 95%;
      }
    }

    @media only screen and (min-width: 1116px) {
      iframe {
        display: flex;
        height: 1023px;
        width: 95%;
      }
    }
  `;
};
