import React, {
  forwardRef,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { useField, useFormikContext } from 'formik';

import getStyles from './styles';
import states from '../../../states.json';

export default forwardRef(function FormikDropdown(props, ref) {
  const id = 'dropdown';
  const { className, styles } = getStyles();
  const { label, className: inputClass, onChange, ...fieldProps } = props;
  const { setErrors, setTouched, setFieldValue } = useFormikContext();
  const [open, setOpen] = useState(false);
  const [field, meta] = useField(fieldProps);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  let customClass = className;

  if (inputClass) customClass = `${inputClass} ${className}`;
  if (open) customClass = `${customClass} open`;
  const { options, disabled } = props;
  if (options && !options.find(({ value }) => value === field.value)) {
    if (field.value !== '') {
      setTouched({});
      setErrors({});
      setFieldValue(field.name, '');
    }
  }

  let error = '';
  if (!open && meta.touched && meta.error) {
    if ((meta.error === 'Required' && !field.value) || (field.value && meta.error !== 'Required')) {
      error = meta.error;
    }
  }

  return (
    <div id={ `${id}-container` } className={ customClass } ref={ ref }>
      <label id={ `${id}-label` } className={ className } htmlFor={ fieldProps.id || fieldProps.name }>{ label }</label>

      <input
        id={ `${id}-input` }
        className={ `${className} ${!open && meta.touched && !!error ? 'error' : null}` }
        onClick={ () => setOpen(!open) }
        size="1"
        readOnly
        { ...field }
        { ...fieldProps }
      />

      <ul id={ `${id}-options` } className={ className }>
        { _.map(options || states, state => (
          <li
            id={ `${id}-option` }
            key={ state.value }
            className={ className }
            onClick={ () => { setFieldValue(field.name, state.value); setOpen(false); if (onChange) { onChange(state); } } }
          >
            { state.value }
          </li>
        )) }
      </ul>

      <div id={ `${id}-open` } className={ `${className} arrow` } onClick={() => (disabled ? () => {} : setOpen(!open))} />
      <span id={ `${id}-error` } className={ `${className} error` }>
        { (!open && meta.touched && error) ? error : <>&nbsp;</> }
      </span>

      { styles }
    </div>
  );
});
