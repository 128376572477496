import React, { Fragment } from 'react'
import Components from '../components';
import getStyles from './styles';
import SbEditable from "storyblok-react";

const HowToList = (props) => {
  const enlargeImages = !!props.blok['images_enlarged'];
  const alignNumbersStart = !!props.blok['align_numbers_left'];
  const fontWeight = props.blok['title_font_weight'];
  const { className, styles } = getStyles(props.blok.list_items.length, enlargeImages, alignNumbersStart, fontWeight);
  const id = 'how-to-list';
  return (
    <SbEditable content={props.blok}>
      <div id={id}>
        <h2 className={ className } id={ `${id}-title` }  dangerouslySetInnerHTML={ { __html: props.blok.title } } />
        <ul className={ className } id={ `${id}` }>
          { props.blok.list_items && props.blok.list_items.map((blok, index) => React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            className,
            index,
          }))}
        </ul>
        { styles }
      </div>
    </SbEditable>
  )
}

export default HowToList
