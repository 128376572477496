import React from 'react'
import SbEditable from 'storyblok-react'
import checkIcon from '../../images/icon_check.png'

const ListItem = (props, index) => (
  <SbEditable content={props.blok}>
    <li id={`list-item-${props.index}`} className={`${props.className || ''} list-item`}>
      { !props.noIcon
        && <img className={`${props.className || ''}`} src={ checkIcon } alt="Check mark icon"/>
      }
      <p id={ `list-item-text-${props.index}` } className={ `${props.className || ''}` } dangerouslySetInnerHTML={ { __html: props.blok.text } } />
    </li>
  </SbEditable>
)

export default ListItem
