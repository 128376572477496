import React from 'react'
import Components from '../../components.js';
import getStyles from './styles';

const Navi = (props) => {
  const id = 'navi';
  const { className, styles } = getStyles();
  return (
      <header id="header" className={className}>
        <div className={ `${className} ${props.blok.nav_items.length ? 'with-nav' : ''}` }>
          { props.blok.logo ?  <img id={ `${id}-logo` } className={ className } alt={ props.blok.logo.alt } src={ props.blok.logo.filename }/> : null }
          { props.blok.nav_items.length
            ? <nav id={ `${id}-nav-items-container` } className={ className }>
              { props.blok.nav_items && props.blok.nav_items.map((blok) => React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok
              }))}
            </nav>
            : ''
          }
          { props.blok['cta_link'] &&
            <a
              id={ `${id}-cta-button` }
              className={ className }
              style={{ 'backgroundColor': props.blok.cta_color }}
              href={ props.blok['cta_link'] || '#' }
            >
              { props.blok['cta_label'] || '' }
            </a>
          }
        </div>
        {styles}
      </header>
  )
}

export default Navi
