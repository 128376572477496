import React from 'react'
import Components from './components.js';
import Footer from './shared/Footer'
import getStyles from '../styles';

const Page = (props) => {
  const { className, styles } = getStyles();
  const customFont = props.blok['custom_font_family'] ? 'custom-font' : '';
  const protectedRoute = props.blok['protected'];
  return (
      <main className={ `${className} ${customFont}` }>
        {props.blok.body && props.blok.body.map((blok) => React.createElement(Components(blok.component), {
          key: blok._uid,
          blok: { ...blok, protectedRoute },
          storyblokClient: props.blok.storyblokClient,
        }))}
        { !props.blok['remove_footer']
          && <Footer blok={props.footer}/>
        }
        {styles}
      </main>
  );
}

export default Page
