import React from 'react'
import Components from '../components';
import getStyles from './styles';
import SbEditable from "storyblok-react";

const FAQ = (props) => {
  const id = 'faq';
  const backgroundColor = props.blok['background_color'];
  const fontWeight = props.blok['title_font_weight'];

  const { className, styles } = getStyles(backgroundColor, fontWeight);
  const { storyblokClient } = props;

  return (
    <SbEditable content={props.blok}>
      <section className={ className }>
        <h2 id={ `${id}-title` } className={ className }>{props.blok.title}</h2>
        <ul id={ `${id}` } className={ className }>
          {props.blok['faq_questions'] && props.blok['faq_questions'].map((blok, index) => React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            storyblokClient,
            className,
            id,
            index,
          }))}
        </ul>
        { styles }
      </section>
    </SbEditable>
  )
}

export default FAQ
