import React from 'react'
import Components from "../../../components";
import SbEditable from 'storyblok-react'
import getStyles from './styles';


const Sitemap = (props) => {
  const id = 'sitemap';
  const { className, styles } = getStyles();
  return (<SbEditable content={props.blok}>
    <div id={ `${id}` } className={ className }>
      <h2 id={ `${id}-header` } className={ className }>{props.blok.header}</h2>
      {props.blok.links && props.blok.links.map((blok) => React.createElement(Components(blok.component), {
        key: blok._uid,
        blok: blok
      }))}
    </div>
    {styles}
  </SbEditable>);
}

export default Sitemap
