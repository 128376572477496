import React from 'react';
import { resolve } from 'styled-jsx/css';
import { brandColor, colors } from '../../../config';

export default (buttonStyles, headerStyles) => {
  return resolve`
    img {
      width: 100%;
    }
    section {
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      box-sizing: border-box;
      position: relative;
      background-size: cover;
      color: ${brandColor};

      .header-container {
        align-self: center;
      }
      header {
        color: ${headerStyles.color || '#FFF'};
        max-width: 750px;
        width: 65%;
        margin: 32px 4%;
        margin-top: ${headerStyles.marginTop};

        &.centered {
          text-align: center;
          width: 100%;
          margin-left: 0;
        }

        h1 {
          font-size: 1.5em;
          font-weight: 700;
          padding: 0;
        }

        h4 {
          font-size: 1em;
          font-weight: 500;
          padding: 0;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        width: 75%;
        align-self: center;
        margin-top: 32px;
        padding: 1em;
        text-align: center;
        align-items: center;
        background-color: #FFFFFF;

        p {
          width: 100%;
          padding: 5px 0px;
          font-size: 115%;
        }

        >div {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 0;
          align-items: center;

          div {
            width: calc(100% + 20px);
            min-width: 170px;

            label {
              padding: 0;
            }

            span {
              position: fixed;
              font-size: 1em;

              &.error {
                color: #FFADAD;
              }
            }
          }
        }

        button {
          display: flex;
          align-items: center;
          align-content: center;
          height: 40px;
          padding: 0;
          border-radius: ${buttonStyles.rounded ? '24px' : '2px'};
          background-color: ${buttonStyles.backgroundColor || colors.accentYellow};
          color: ${buttonStyles.color || brandColor};
          margin-left: -5px;
          justify-content: center;
          text-decoration: none;
          width: 100%;

          span {
            &.loading {
              opacity: 0;
            }
          }

          .spinner {
            position: absolute;
            animation: around 3s infinite;
          }

          .spinner::after {
            animation: around 0.7s ease-in-out 0.2s infinite;
            background: transparent;
          }

          @keyframes around {
            100% {
              transform: rotate(0deg)
            }
            0% {
              transform: rotate(1080deg)
            }
          }

          &[disabled] {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }

    @media only screen and (min-width: 398px) {
      section {
        header {
          h1 {
            font-size: 2em;
          }
        }
      }
    }
    @media only screen and (min-width: 520px) {
      section {
        header {
          h1 {
            font-size: 2.5em;
          }

          h4 {
            font-size: 1.2em;
          }
        }
      }
    }
    @media only screen and (min-width: 768px) {
      section {
        header {
          &.withBackground {
            width: 100%;
            margin-left: 0;
          }
          h1 {
            font-size: 3.5em;
          }

          h4 {
            font-size: 1.5em;
          }
        }
        form {
          p {
            padding: 5px 32px;
            width: 75%;
          }

          >div {
            display: grid;
            grid-template-columns: 3fr 1fr;
            max-width: 666px;

            div {
              width: 100%;
            }
          }

          button {
            height: 48px;
            margin-left: ${buttonStyles.rounded ? '16px' : '-5px'};
            margin-top: -13px;
            span {
              font-size: 0.9em;
            }
          }
        }
      }
    }
    @media only screen and (min-width: 980px) {
      section {
        form {
          &.withBackground {
            position: absolute;
            bottom: 10%;
            max-width: 750px;
          }
        }
      }
    }
    @media only screen and (min-width: 1200px) {
      section {
        form {
          &.withBackground {
            bottom: 8vw;
          }
        }
      }
    }
  `;
};
