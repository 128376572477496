import React from 'react';
import { resolve } from 'styled-jsx/css';

import { brandColor, colors } from '../../../config';

export default (textColor, withBackground) => {
  return resolve`
    section {
      flex: 0 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      box-sizing: border-box;
      width: 100%;
      height: 80%;
      margin: 0 auto;
      justify-content: flex-start;
      flex-grow: 1;
      text-align: center;
      background-size: cover;
      background-repeat: no-repeat;

      img {
        width: 100%;
      }
      
      a {
        color: ${colors.accentLink};
      }
      
      header {
        position: ${withBackground ? 'absolute' : 'relative'};
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: ${withBackground ? '-30px' : '0'};
        color: ${textColor || 'inherit'};

        h1 {
          font-size: 1.5em;
          font-weight: 700;
        }
        p {
          margin-top: 8px;
          width: 95%;
          font-size: 1em;
          text-align: center;
        }
      }
      
      button {
        display: flex;
        align-items: center;
        align-content: center;
        height: 32px;
        margin-top: 16px;
        border-radius: 24px;
        padding: 0 24px;
        background-color: ${colors.accentYellow};
        color: ${brandColor};
        font-size: 1.25em;
        font-weight: 600;
        justify-content: center;
        text-decoration: none;
      }

      span {
        margin: 16px 0;
        font-size: 1.25em;
        font-weight: bold;
      }
    }

    @media only screen and (min-width: 768px) {
      section {
        &:not(.full-width) {
          width: 95%;
        }

        button {
          height: 48px;
          margin-top: 48px;
        }

        header {
          h1 {
            font-size: 2.75em;
          }
          p {
            font-size: 1.25em;
          }
        }
      }
    }
    @media only screen and (min-width: 980px) {
      section {
        header {
          h1 {
            font-size: 4em;
          }
          p {
            font-size: 2em;
          }
        }
      }
    }
  `;
};
