import { gql } from '@apollo/client';

export default gql`
  mutation enrollPartnerDriver($input: EnrollPartnerDriverInput!) {
    enrollPartnerDriver(input: $input) {
      activationUrl
      errorCode
      errorMessage
      message
      success
    }
  }
`;
