import Page from './page';
import Grid from './shared/grid';
import Confirmation from './Confirmation';
import Step from './Confirmation/Step';
import DualLogoHeader from './DualLogoHeader';
import Teaser from './Teaser';
import Iframe from './Iframe';
import List from './shared/List';
import ListItem from './shared/list_item';
import HowToList from './HowToList';
import HowToListItem from './HowToList/ListItem';
import Navi from './shared/Navi';
import NavItem from './NavItem';
import PromoCodeEntry from './PromoCodeEntry';
import ErrorComponent from './Error';
import RegisterForm from './RegisterForm';
import RegisterView from './views/RegisterView';
import RegisterRouter from './RegisterRouter';
import StandAloneRouter from './StandAloneRouter';
import Sitemap from './shared/Footer/sitemap';
import SitemapLink from './shared/Footer/sitemap_link';
import FastCharging from './FastCharging';
import FormBanner from './FormBanner';
import MobileAppBanner from './MobileAppBanner';
import AppIcon from './MobileAppBanner/app_icon';
import TwoPhotos from './TwoPhotos';
import PartnerBanner from './PartnerBanner';
import FAQ from './FAQ';
import FAQQuestion from './FAQ/question';
import Legal from './Legal';
import ComponentNotFound from './component_not_found';
import PricingWidget from './PricingWidget';
import AlternatingFeature from './AlternatingFeature';
import ImageFeature from './ImageFeature';

const ComponentList = {
  alternating_feature: AlternatingFeature,
  app_icon: AppIcon,
  confirmation: Confirmation,
  dual_logo_header: DualLogoHeader,
  error: ErrorComponent,
  fast_charging: FastCharging,
  faq_section: FAQ,
  faq_question: FAQQuestion,
  form_banner: FormBanner,
  grid: Grid,
  how_to_list: HowToList,
  how_to_list_item: HowToListItem,
  iframe: Iframe,
  image_feature: ImageFeature,
  legal: Legal,
  list: List,
  list_item: ListItem,
  navi: Navi,
  mobile_app_banner: MobileAppBanner,
  nav_item: NavItem,
  page: Page,
  partner_banner: PartnerBanner,
  promocode_entry: PromoCodeEntry,
  pricing_widget: PricingWidget,
  register_form: RegisterForm,
  register_router: RegisterRouter,
  register_view: RegisterView,
  sitemap: Sitemap,
  stand_alone_router: StandAloneRouter,
  sitemap_link: SitemapLink,
  step: Step,
  teaser: Teaser,
  two_photos: TwoPhotos,
};

const Components = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
