import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
      div {
        flex: 2;
        display: flex;
        flex-direction: column;
        margin: 32px 0 0;

        h2 {
          margin: 8px 0;
          padding: 0;
          font-size: 1.5em;
        }
      }

      @media only screen and (min-width: 980px) {
        div {
          margin: 0 32px;
        }
      }
  `;
};
