import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
    div {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 5px 10px 0;

      label {
        padding-bottom: 2px;
        font-size: 14px;
      }

      input {
        box-sizing: border-box;
        height: 48px;
        border: 2px solid rgba(0,0,0,0.25);
        border-radius: 4px;
        padding: 8px;
        color: #002B39;
        font-size: 20px;
        font-weight: 100;

        &.error {
          border-color: #CC0000;
        }

        &[disabled] {
          color: #AAAAAA;
        }
      }

      span {
        color: #CC0000;
        font-size: 14px;
      }
    }

    @media screen and (max-width: 425px) {
      div {
        input {
          height: auto;
          font-size: 1.3em;
          line-height: 1.2em;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      div {
        input {
          padding: 8px 18px;
        }
        &.half {
          flex-basis: 50%;
        }

        &.quarter {
          flex-basis: 25%;
        }

        &.quarter {
          flex-basis: 25%;
        }
      }
    }

  `;
};
