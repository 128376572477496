import React, { Fragment } from 'react';
import getStyles from './styles';
import SbEditable from 'storyblok-react';

/**
 * Dual Logo Header component
 * @returns {Object}
 */
export default function DualLogoHeader(props) {
  const id = 'dual-logo-header';
  const { className, styles } = getStyles();

  return (
    <SbEditable content={props.blok}>
    <section id={ `${id}` } className={ className }>
      { props.blok['left_logo'] && props.blok['right_logo']
      && <Fragment>
        <div className={ className }>
          <img id={ `${id}-image-1` } className={ `${className} left-img` } alt={props.blok['left_logo'].alt} src={props.blok['left_logo'].filename} />
        </div>
        <div className={ className }>
          { props.blok.link 
            && <a className={ className } style={{ color: props.blok['link_color'] || '#000' }} href={ props.blok.link.url } target="_blank">{ props.blok['link_display_name'] }</a>
          }
          <img id={ `${id}-image-2` } className={ `${className} right-img` } alt={props.blok['right_logo'].alt} src={props.blok['right_logo'].filename} />
        </div>
      </Fragment>
      }
      { styles }
    </section>
    </SbEditable>
  );
}
