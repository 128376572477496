import React from 'react';
import { resolve } from 'styled-jsx/css';

export default (backgroundColor, fontWeight) => {
  return resolve`
    section {
      background: ${backgroundColor || ''};
      flex-direction: column;
      margin-bottom: 0;

      h2 {
        text-align: center;
        padding: 0;
        font-size: 2em;
        margin: 24px 0;
        font-weight: ${fontWeight || 'auto'};
      }
      h3 {
        font-size: 16pt;
      }
      ul {
        font-size: 1.2em;
        list-style: none;
        width: 85%;
        margin: 0 auto 48px;
        li {
          margin-top: 2em;
        }
        div {
          margin-top: 24px;
          font-size: 12pt;
        }
      }
    }


    @media only screen and (min-width: 980px) {
      section {
        h2 {
          font-size: 3em;
        }
      }
    }
  `;
};
