import React from 'react';
import { resolve } from 'styled-jsx/css';

import { brandColor, colors } from '../../../../config';

export default () => {
  return resolve`
    footer {
      box-sizing: border-box;
      color: white;
      width: 100%;
      background-color: ${brandColor};
      
      hr {
        margin: 0 64px;
        border: none;
        border-top: 1px solid ${colors.accentYellow};
      }

      section {
        display: flex;
        align-items: start;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        padding: 64px 64px 48px;
        margin-bottom: 0;
        
        a {
          color: white;
          font-weight: 300;
          text-decoration: none;
        }
        
        > div {
          flex: 2;
          display: flex;
          flex-direction: column;
          margin: 32px 0 0;
          
          a {
            margin: 4px 0;
          }
          
          form {
            display: flex;
            margin-top: 32px;
            
            #mc_embed_signup_scroll {
              flex: 1;
              display: flex;
              align-items: center;
              align-content: center;
              border-bottom: 1px solid rgba(255,255,255,0.5);
              
              button {
                padding: 0;
                background-color: transparent;
                color: rgba(255,255,255,0.5);
              }

              label {
                flex: 1;
                display: flex;

                input {
                  flex: 1;
                  background-color: transparent;
                  color: rgba(255,255,255,0.5);
                }
                
                input:last-child {
                  display: none;
                }
              }
            }
          }
          
          h2 {
            margin: 8px 0;
            padding: 0;
          }
          
          #footer-logo {
            width: 120px;
          }
          
          &.social {
            flex: 0 1 auto;
            flex-direction: row;
            margin: 16px 0 0;

            a {
              display: flex;
              align-items: center;
              align-content: center;
              background-color: white;
              width: 24px;
              height: 24px;
              margin: 0 8px;
              border-radius: 12px;
              color: ${brandColor};
              font-size: 16px;
              justify-content: center;
              text-align: center;
              
              &#footer-to-header {
                margin-right: 0;
                background-color: ${brandColor};
                width: auto;
                color: white;
              }
            }
          }
          
          &:first-child:not(.social) {
            flex: 3;
            margin-top: 0;
            margin-left: 0;
            
            img {
              max-width: 50%;
              margin: 0 0 24px;
            }
          }
          
          &:last-child:not(.social) {
            flex: 0 1 auto;
            margin-right: 0;
            
            a {
              margin: 0;
            }
            
            img {
              margin: 8px 0;
              height: 48px;
            }
          }
        }
        
        > span {
          margin: 0;
          text-align: center;
          
          &:first-of-type {
            margin-left: 0;
          }
          
          &:last-of-type {
            margin-top: 16px;
            margin-right: 0;
          }
        }
        
        &:last-child {
          align-items: center;
          align-content: center;
          padding: 32px 64px;
        }
      }
    }

    @media only screen and (min-width: 980px) {
      footer {
        
        section {
          flex-direction: row;
          
          > div {
            margin: 0 32px;
            
            &.social {
              margin: 0 0 0 auto;
            }
          }
          
          > span {
            margin: 0 64px;
            text-align: left;
            
            &:last-of-type {
              margin-top: 0;
            }
          }
          
          &:last-child {
            align-items: flex-start;
            align-content: flex-start;
          }
        }
      }
    }
  `;
};
