import React from 'react';
import { resolve } from 'styled-jsx/css';
import { brandColor, colors } from '../../../../config';

export default () => {
  return resolve`
    section {
      flex-direction: column;
      width: 85%;
    }

    h2 {
      text-align: center;
      padding: 0;
      font-size: 2em;
      margin-top: 1em;
    }

    ul {
      font-size: 1.2em;
      list-style: none;
      margin: 0 auto 48px;

      li {
        position: relative;
        margin-top: 1em;
        padding-left: 30px;
        img {
          position: absolute;
          height: 20px;
          top: 3px;
          left: 0;
        }            
      }
    }

    @media only screen and (min-width: 980px) {
      h2 {
        font-size: 3em;
      }
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 50px;
      }
    }
  `;
};
