import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
    section {
      flex-direction: column;
      margin-bottom: 0;
    }

    a {
      padding: 35px;

      img {
        object-fit: contain;
        max-width: 100%;
        width: 250px;
      }
    }  
  `;
};
