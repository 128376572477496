import React from 'react';

import getStyles from './styles';
import SbEditable from "storyblok-react";
/**
 * Fast Charging component
 * @returns {Object}
 */
export default function FastCharging(props) {
  const id = 'fastcharging';
  const backgroundColor = props.blok['background_color'] ? props.blok['background_color']  : '';
  const fontWeight = props.blok['title_font_weight'];
  const { className, styles } = getStyles(backgroundColor, fontWeight);
  const photoFirst = props.blok['flip_order'] ? 'photo-first' : '';
  const fullWidth = props.blok['full_width'] ? 'full-width' : '';

  return (
    <SbEditable content={props.blok}>
    <section className={ `${className} ${fullWidth}` } id={ `${id}` }>
      <div className={ `${className} ${photoFirst}` }>
        <h2 className={ className } id={ `${id}-title` }>{props.blok.title}</h2>
        <span className={ className } dangerouslySetInnerHTML={{ __html: props.blok.description }}/>
      </div>

      <div className={ className }>
        <img className={className} alt={props.blok.image.alt} src={props.blok.image.filename} id={ `${id}-image` } />
      </div>

      { styles }
    </section>
    </SbEditable>
  );
}
