import React, { Fragment } from 'react';
import Components from '../components.js';
import getStyles from './styles';
import SbEditable from 'storyblok-react';
import { useLocation } from '@reach/router';
import { brandColor } from '../../../config';

/**
 * Confirmation component
 * @props {Object} props - React props
 * @returns {Object}
 */
export default function Confirmation(props) {
  const id = 'confirmation';
  const loginHeaderTextColor = props.blok['login_header_text_color'] ? props.blok['login_header_text_color']  : '';
  const registrationHeaderTextColor = props.blok['registration_header_text_color'] ? props.blok['registration_header_text_color']  : '';
  const { className, styles } = getStyles();
  const location = useLocation();
  const action = location.state && location.state.action ? location.state.action : 'registration';

  return (
    <SbEditable content={ props.blok }>
      <section className={ className } id={`${id}`}>
        { action === 'login' && (props.blok['login_header_photo'] || props.blok['login_header'] || props.blok['login_header'])
          && <div className={ `${className} header-container` } style={{ color: loginHeaderTextColor || brandColor }}>
            <header className={ className }>
              <h2 id={ `${id}-header` } className={ className }>{ props.blok['login_header'] }</h2>
              <span id={ `${id}-subheader` } className={ className }>{ props.blok['login_subheader'] }</span>
            </header>
            <img id={ `${id}-header-image` } className={ className } alt={props.blok['login_header_photo'].alt || ''} src={props.blok['login_header_photo'].filename} />
          </div>
        }
        { action === 'registration' && (props.blok['registration_header_photo'] || props.blok['registration_header'] || props.blok['registration_header'])
          && <div className={ `${className} header-container` } style={{ color: registrationHeaderTextColor || brandColor }}>
            <header className={ className }>
              <h2 id={ `${id}-header` } className={ className }>{ props.blok['registration_header'] }</h2>
              <span id={ `${id}-subheader` } className={ className }>{ props.blok['registration_subheader'] }</span>
            </header>
            <img id={ `${id}-header-image` } className={ className } alt={props.blok['registration_header_photo'].alt || ''} src={props.blok['registration_header_photo'].filename} />
          </div>
        }
        <h1 className={ className } id={`${id}-title`}>
          { action === 'login' ? props.blok['login_title'] : props.blok['registration_title'] }
        </h1>

        <p className={ className } id={`${id}-subtitle`}>
          { action === 'login' ? props.blok['login_subtitle'] : props.blok['registration_subtitle'] }
        </p>

        { action && props.blok[`${action}_steps`]
          && <div className={ className } id={ `${id}-${action}-steps-container` }>
              { props.blok[`${action}_steps`].length
                  ? <ol className={ className }>
                  { props.blok[`${action}_steps`] && props.blok[`${action}_steps`].map((blok, index) => React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                    index,
                  }))}
                </ol>
                : ''
              }
          </div>
        }

        { action === 'login' && props.blok['render_login_cta_button']
          && <Fragment>
            <a
              id={`${id}-login-button`}
              className={ `${className} button` }
              href="https://account.evgo.com/login"
              rel="noreferrer"
              target="_blank">
              Log into your account
            </a>
          </Fragment>
        }
      </section>

      { styles }
    </SbEditable>
  );
}
