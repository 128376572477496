import React from 'react';
import _ from 'lodash';
import { useField } from 'formik';

import getStyles from './styles';

export default function FormikTextInput(props) {
  const id = 'text-input';
  const { className, styles } = getStyles();
  const { label, className: inputClass, error, ...fieldProps } = props;
  const [field, meta] = useField(fieldProps);
  let customClass = className;

  if (inputClass) customClass = `${inputClass} ${className}`;

  return (
    <div className={ customClass } id={ `${id}-container` }>
      { label ? <label id={ `${id}-label` } className={ className } htmlFor={ fieldProps.id || fieldProps.name }>{ label }</label> : null }

      <input
        id={ `${id}` }
        className={ `${className} ${(meta.touched && meta.error) || !_.isEmpty(error) ? 'error' : ''}` }
        size="1"
        { ...field }
        { ...fieldProps }
      />

      <span id={ `${id}-error` } className={ `${className} error` }>
        { (meta.touched && meta.error) ? meta.error : <>&nbsp;</> }
        { error ? error : <>&nbsp;</> }
      </span>

      { styles }
    </div>
  );
}
