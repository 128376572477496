import React from 'react';
import SbEditable from 'storyblok-react';
import * as styles from './styles.module.css';

const ImageFeature = (props) => {
  const { title, image, link, link_text, order, _uid } = props.blok;
  const isTextFirst = order === '1' ? styles.textFirst : styles.imgFirst;
  return (
    <SbEditable content={props.blok}>
      <section id={_uid} className={`${styles.sectionContainer} ${isTextFirst}`}>
        <div className={styles.textContainer}>
          <h4 className={styles.title}>{title}</h4>
          <a className={styles.link} href={link.url} target="_blank">
            {link_text}
          </a>
        </div>
        <div className={styles.imgWrapper}>
          <img className="" alt={image.alt} src={image.filename} />
        </div>
      </section>
    </SbEditable>
  );
};

export default ImageFeature;
