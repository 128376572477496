import React, { createRef, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import FormikTextInput from '../shared/FormikTextInput';
import FormikDropdown from '../shared/FormikDropdown';
import FormikCheckbox from '../shared/FormikCheckbox';
import { Form, Formik } from 'formik';
import { useNavigate, useLocation } from '@reach/router';
import { listPortalsVehicleMakes, listPortalsVehicleModels } from '../../apollo/queries/vehicleLookup';
import {
  enrollPartnerDriver,
  getPartnerDriverEmail,
  partnerDriverLinked,
  partnerDriverNotLinked,
} from '../../apollo/queries/partnerDrivers';
import { redeemPromotion } from '../../apollo/queries/promotionCodes';
import { enrollPortalsDriver } from '../../apollo/queries/drivers';
import getStyles from './styles';
import validationSchema from './validations';
import { Sync } from '@material-ui/icons';
import { v1 as uuidv1 } from 'uuid';

const RegisterForm = (props) => {
  const id = 'register-form';
  const { className, styles } = getStyles();
  const stateRef = createRef();
  const makeRef = createRef();
  const modelRef = createRef();
  const yearRef = createRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState();
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [year, setYear] = useState('');
  const [subscriptionId, setSubscriptionId] = useState();

  useEffect(() => {
    if (props.blok['redirect_url'] && props.blok['redirect_url'].url) {
      setSubscriptionId(uuidv1());
    }
  }, []);

  const [hashEmail] = useMutation(getPartnerDriverEmail, {
    onCompleted(res) {
      if (res && res.getPartnerDriverEmail) {
        const emailAddress = res.getPartnerDriverEmail;
        const redirectUrl = `${props.blok['redirect_url'].url}${
          process.env.GATSBY_AWS_LAMBDA_HOST
        }&state=${JSON.stringify({ subscriptionId, emailAddress, action: 'registration' })}`;
        window.open(redirectUrl, 'uber', 'width=600,height=300');
      } else {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', { state: { error: '' } });
      }
    },
    onError() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: '' } });
    },
  });
  const { data: makes } = useQuery(listPortalsVehicleMakes);
  const [onListModels, { data: models }] = useLazyQuery(listPortalsVehicleModels);

  const makeOptions =
    makes && makes.listPortalsVehicleMakes
      ? makes.listPortalsVehicleMakes.map(({ text, value }) => ({ value: text, name: value }))
      : [];
  let modelOptions =
    models && models.listPortalsVehicleModels
      ? models.listPortalsVehicleModels.map(({ text, value }) => ({ value: text, name: value }))
      : [];
  if (props.blok['vehicle_models']) {
    const vehicleMakes = props.blok['vehicle_models'].split(',');
    modelOptions = vehicleMakes ? modelOptions.filter((m) => m.name && _.includes(vehicleMakes, m.name)) : modelOptions;
  }

  const [defaultVehicleMake, setDefaultVehicleMake] = useState(null);
  useEffect(() => {
    if (props.blok['selected_vehicle_make'] && _.isEmpty(defaultVehicleMake)) {
      _.map(makeOptions, (make) => {
        if (make.value === props.blok['selected_vehicle_make']) {
          setDefaultVehicleMake(make);
        }
      });
    }
  }, [defaultVehicleMake, makeOptions, setDefaultVehicleMake]);

  const startYear = 2000;
  const yearOptions = Array.from(Array(new Date().getFullYear() - startYear + 2).keys())
    .map((i) => {
      return { value: String(startYear + i), name: String(startYear + i) };
    })
    .reverse();

  const [onCreateEnrollmentPlan] = useMutation(enrollPartnerDriver, {
    onCompleted(res) {
      if (res && res.enrollPartnerDriver && res.enrollPartnerDriver.success && res.enrollPartnerDriver.activationUrl) {
        // activationUrl indicates a new account was created
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (props.blok['confirmation_route'] && props.blok['confirmation_route'].url) {
          navigate(props.blok['confirmation_route'].url, { state: { action: 'registration' } });
        } else {
          navigate('/confirmation', { state: { action: 'registration' } });
        }
      } else if (
        res &&
        res.enrollPartnerDriver &&
        res.enrollPartnerDriver.success &&
        !res.enrollPartnerDriver.activationUrl
      ) {
        // no activationUrl indicates promo was added to an existing account
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (props.blok['confirmation_route'] && props.blok['confirmation_route'].url) {
          navigate(props.blok['confirmation_route'].url, { state: { action: 'login' } });
        } else {
          navigate('/confirmation', { state: { action: 'login' } });
        }
      } else {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', {
          state: {
            error: res.enrollPartnerDriver.errorCode || '',
            message: res.enrollPartnerDriver.message || '',
          },
        });
      }
    },
    onError(err) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: err.message || '' } });
    },
  });

  const [registerDriver] = useMutation(enrollPortalsDriver, {
    onCompleted(res) {
      if (res && res.enrollPortalsDriver && res.enrollPortalsDriver.success && res.enrollPortalsDriver.activationUrl) {
        // activationUrl indicates a new account was created
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (props.blok['confirmation_route'] && props.blok['confirmation_route'].url) {
          navigate(props.blok['confirmation_route'].url, { state: { action: 'registration' } });
        } else {
          navigate('/confirmation', { state: { action: 'registration' } });
        }
      } else if (res && res.enrollPortalsDriver && res.enrollPortalsDriver.success && !res.enrollPortalsDriver.activationUrl) {
        // no activationUrl indicates promo was added to an existing account
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (props.blok['confirmation_route'] && props.blok['confirmation_route'].url) {
          navigate(props.blok['confirmation_route'].url, { state: { action: 'login' } });
        } else {
          navigate('/confirmation', { state: { action: 'login' } });
        }
      } else {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', {
          state: {
            error: res.enrollPortalsDriver.errorCode || '',
            message: res.enrollPortalsDriver.message || '',
          },
        });
      }
    },
    onError(err) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: err.message || '' } });
    },
  });

  const [applyPromotion] = useMutation(redeemPromotion, {
    onCompleted(res) {
      if (res && res.redeemPromotion && res.redeemPromotion.success) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (props.blok['confirmation_route'] && props.blok['confirmation_route'].url) {
          navigate(props.blok['confirmation_route'].url, { state: { action: 'registration' } });
        } else {
          navigate('/confirmation', { state: { action: 'registration' } });
        }
      }
    },
    onError(err) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: err.message || '' } });
    },
  });

  const formRef = useRef();

  const {
    data: subscriptionData,
    error: subscriptionError,
    loading: subscriptionLoading,
  } = useSubscription(partnerDriverLinked, {
    variables: {
      input: {
        subscriptionId,
      },
    },
    skip: !subscriptionId,
  });

  useSubscription(partnerDriverNotLinked, {
    variables: {
      input: {
        subscriptionId,
      },
    },
    skip: !subscriptionId,
    onSubscriptionData: ({ subscriptionData }) => {
      const { data, error, loading } = subscriptionData;
      if (!loading && data) {
        const {
          partnerDriverNotLinked: { message },
        } = data;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', { state: { error: message, message } });
      }
      if (!loading && error) {
        const { message } = error;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', { state: { error: message, message } });
      }
    },
  });

  useEffect(() => {
    if (!subscriptionLoading && subscriptionData) {
      const {
        partnerDriverLinked: { partnerDriverId },
      } = subscriptionData;
      if (partnerDriverId) {
        handleSubmit({ ...formRef.current.values, partnerDriverId });
      }
    }
  }, [subscriptionData, subscriptionLoading]);

  useEffect(() => {
    if (subscriptionError) {
      const { message } = subscriptionError;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: message, message } });
    }
  }, [subscriptionError, subscriptionLoading]);

  const promoCodeDetails = location.state && location.state.promoCodeDetails ? location.state.promoCodeDetails : '';

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    make: props.blok['selected_vehicle_make'],
    model: '',
    year: props.blok['default_vehicle_year'],
    password: '',
    confirmPassword: '',
    subscribe: true,
    terms: false,
    subscriptionCopy: props.blok.subscriptionCopy,
  };

  const handleChange = useCallback(
    ({ name, value }, type) => {
      switch (type) {
        case 'make':
          setMake(value);
          setModel('');
          setYear('');
          onListModels({ variables: { modelInput: { altId: name } } });
          break;
        case 'model':
          setModel(value);
          setYear('');
          break;
        case 'year':
          setYear(value);
          break;
        default:
          break;
      }
    },
    [onListModels],
  );

  useEffect(() => {
    if (!_.isEmpty(defaultVehicleMake) && defaultVehicleMake.value !== make.value) {
      handleChange(defaultVehicleMake, 'make');
      formRef.current.values.make = defaultVehicleMake.value;
    }
  }, [defaultVehicleMake, make, handleChange]);

  const handleSubmit = ({ confirmPassword, ...contractInput }) => {
    const input = !_.isEmpty(promoCodeDetails)
      ? {
          promotionCode: promoCodeDetails.promotionCode,
          promotionRef: promoCodeDetails.promotionRef,
          planCode: props.blok['plan_code'],
          platformName: props.blok['platform_name'],
          ...contractInput,
        }
      : {
          planCode: props.blok['plan_code'],
          platformName: props.blok['platform_name'],
          ...contractInput,
        };
    const params = {
      variables: {
        input,
      },
    };
    if (props.blok['redirect_url'] && props.blok['redirect_url'].url) return onCreateEnrollmentPlan(params);
    if (!_.isEmpty(promoCodeDetails)) return applyPromotion(params);
    return registerDriver(params);
  };

  const handlePartnerLogin = ({ email }) => {
    return hashEmail({
      variables: {
        input: {
          email,
        },
      },
    });
  };

  const handleRegister = async (values) => {
    setLoading(true);
    return props.blok['redirect_url'] && props.blok['redirect_url'].url
      ? handlePartnerLogin(values)
      : handleSubmit(values);
  };

  if (
    formRef &&
    formRef.current &&
    formRef.current.values &&
    formRef.current.values.make === 'BMW' &&
    !_.isEmpty(modelOptions)
  ) {
    const filterArray = [
      '1 Active E',
      'ActiveE',
      'Vision',
      'Megacity I3',
      '530e',
      '740e xDrive iPerformance',
      '740e xDrive Plug-In Hybrid',
    ];
    modelOptions = _.filter(modelOptions, (o) => {
      return !filterArray.includes(o.value);
    });

    modelOptions = _.map(modelOptions, (model) => {
      if (model.value === 'I3' || model.value === 'I8') {
        model.value = model.value.toLowerCase();
      }
      return model;
    });

    modelOptions.sort((a, b) => {
      if (
        a.value.charAt(0) === a.value.charAt(0).toLowerCase() &&
        _.isNan(Number(a.value.charAt(0))) &&
        b.value.charAt(0) === b.value.charAt(0).toUpperCase()
      ) {
        return -1;
      }
      if (
        a.value.charAt(0) === a.value.charAt(0).toLowerCase() &&
        _.isNan(Number(a.value.charAt(0))) &&
        !_.isNan(b.value.charAt(0))
      ) {
        return 1;
      }
      if (
        a.value.charAt(0) === a.value.charAt(0).toUpperCase() &&
        _.isNan(Number(a.value.charAt(0))) &&
        !_.isNan(b.value.charAt(0))
      ) {
        return 1;
      }
      if (a.value == b.value) return 0;
      return a.value < b.value ? -1 : 1;
    });
  }

  return (
    <Fragment>
      <Formik
        innerRef={formRef}
        onSubmit={handleRegister}
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnMount={true}
      >
        {(formik) => (
          <Form className={className} id={`${id}`}>
            <h3 className={className}>Account Details</h3>
            <FormikTextInput
              id={`${id}-first-name`}
              className={`${className} half`}
              name="firstName"
              label="First Name*"
              data-qa="first-name"
            />
            <FormikTextInput
              id={`${id}-last-name`}
              className={`${className} half`}
              name="lastName"
              label="Last Name*"
              data-qa="last-name"
            />
            <FormikTextInput
              id={`${id}-email`}
              className={`${className} half`}
              name="email"
              label="Email*"
              data-qa="email"
            />
            <FormikTextInput
              id={`${id}-phone`}
              className={`${className} half`}
              name="phone"
              type="tel"
              label="Contact Number*"
              data-qa="phone"
            />

            <h3 className={className}>Address</h3>
            <FormikTextInput
              id={`${id}-address`}
              className={className}
              name="address"
              label="Street Address*"
              data-qa="address"
            />
            <FormikTextInput
              id={`${id}-city`}
              className={`${className} half`}
              name="city"
              label="City*"
              data-qa="city"
            />
            <FormikDropdown
              id={`${id}-state`}
              className={`${className} quarter`}
              name="state"
              label="State*"
              data-qa="state"
              ref={stateRef}
            />
            <FormikTextInput
              id={`${id}-postalCode`}
              className={`${className} quarter`}
              name="postalCode"
              label="Zip*"
              data-qa="postal-code"
            />

            <h3 className={className}>Vehicle Information</h3>
            {!_.isEmpty(defaultVehicleMake) ? (
              <FormikTextInput
                id={`${id}-make`}
                className={`${className} half`}
                name="make"
                label="Make*"
                data-qa="vehicle-make"
                value={defaultVehicleMake.value}
                ref={makeRef}
                disabled
              />
            ) : (
              <FormikDropdown
                id={`${id}-make`}
                className={`${className} half`}
                name="make"
                label="Make*"
                value={make}
                onChange={(value) => handleChange(value, 'make')}
                options={makeOptions}
                data-qa="vehicle-make-dropdown"
                ref={makeRef}
              />
            )}
            <FormikDropdown
              id={`${id}-model`}
              value={model}
              className={`${className} quarter`}
              disabled={!make}
              name="model"
              label="Model*"
              onChange={(value) => handleChange(value, 'model')}
              options={modelOptions}
              data-qa="vehicle-model-dropdown"
              ref={modelRef}
            />
            <FormikDropdown
              id={`${id}-year`}
              value={year || props.blok['default_vehicle_year']}
              className={`${className} quarter`}
              disabled={!make}
              name="year"
              label="Year*"
              onChange={(value) => handleChange(value, 'year')}
              options={yearOptions}
              data-qa="vehicle-year-dropdown"
              ref={yearRef}
            />

            <h3 className={className}>Security</h3>
            <FormikTextInput
              id={`${id}-password`}
              className={`${className} half`}
              name="password"
              label="Password*"
              type="password"
              data-qa="password"
            />
            <FormikTextInput
              id={`${id}-confirm-password`}
              className={`${className} half`}
              name="confirmPassword"
              label="Confirm Password*"
              type="password"
              data-qa="confirm-password"
            />

            <h6 className={className}>
              Minimum 8 chars: with at least 1 number, 1 uppercase, 1 lowercase
              &&nbsp;1&nbsp;special&nbsp;character&nbsp;(!,&nbsp;@,&nbsp;#&nbsp;...)
            </h6>
            <hr className={className} />
            {props.blok['terms_conditions_copy'] ? (
              <FormikCheckbox id={`${id}-terms`} className={className} name="terms" data-qa="terms-checkbox">
                <div className={className} dangerouslySetInnerHTML={{ __html: props.blok['terms_conditions_copy'] }} />
              </FormikCheckbox>
            ) : (
              <FormikCheckbox id={`${id}-terms`} className={className} name="terms" data-qa="terms-checkbox">
                By checking this box, you acknowledge you have read and agree to be bound by our{' '}
                <a target="_blank" className={className} href="https://www.evgo.com/terms-service/">
                  Terms of Service
                </a>{' '}
                and our{' '}
                <a target="_blank" className={className} href="https://www.evgo.com/privacy-policy/">
                  Privacy Policy
                </a>
                . By submitting this form, you acknowledge you are sharing your personal information with EVgo and
                consent to EVgo’s Privacy Notice for California Residents. More detail regarding personal information we
                collect, how we use that information, how we share that information, and your rights and choices can be
                found in our{' '}
                <a target="_blank" className={className} href="https://www.evgo.com/privacy-policy/">
                  Privacy Policy
                </a>
                .
              </FormikCheckbox>
            )}
            <FormikCheckbox id={`${id}-subscribe`} className={className} name="subscribe" data-qa="subscribe-checkbox">
              {props.blok.subscriptionCopy}
            </FormikCheckbox>
            <div className={`${className} submit-container`}>
              <button
                id={`${id}-submit-button`}
                className={className}
                type="submit"
                disabled={!!loading || !formik.isValid}
              >
                <span className={`${className} ${loading ? 'loading' : ''}`}>
                  {props.blok['register_submit_button_copy']}
                </span>
                {loading ? <Sync className={`${className} spinner`} /> : null}
              </button>
              <p className={className}>{props.blok['register_submit_button_subtext']}</p>
              {props.blok['disclaimer_text'] && (
                <>
                  <hr className={className} />
                  <p className={`${className} disclaimer-text`}>{props.blok['disclaimer_text']}</p>
                </>
              )}
            </div>
            {styles}
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default RegisterForm;
