import React from 'react'
import SbEditable from 'storyblok-react'
import getStyles from './styles';

const Iframe = (props) => {
  const { className, styles } = getStyles();
  return (
    <SbEditable content={props.blok}>
      {props.blok.url ?
        <section id={ props.blok['internal_link'] || '' } className={ className }>
          <iframe className={ className } src={props.blok.url.url} id="iframe" />
        </section>
          : null
      }
      { styles }
    </SbEditable>
  )
}

export default Iframe
