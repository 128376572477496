import { gql } from '@apollo/client';

/**
 * Partner Driver linked subscription
 * @return {Promise<Object>}
 */
export default gql`
  subscription partnerDriverLinked($input: PartnerDriverLinkedInput!) {
    partnerDriverLinked(input: $input) {
      subscriptionId
      partnerDriverId
    }
  }
`;
