import React, { Fragment, useEffect, useRef, useState } from 'react';

import { navigate } from 'gatsby';
import { Form, Formik } from 'formik';
import { useMutation, useSubscription } from '@apollo/client';
import { Sync } from '@material-ui/icons';
import { useLocation } from '@reach/router';
import FormikTextInput from '../shared/FormikTextInput';
import FormikCheckbox from '../shared/FormikCheckbox';
import _ from 'lodash';

import getStyles from './styles';
import validationSchema from './validations';
import {
  getPartnerDriverEmail,
  redeemPartnerDriver,
  partnerDriverLinked,
  partnerDriverNotLinked,
} from '../../apollo/queries/partnerDrivers';
import { redeemPromotion } from '../../apollo/queries/promotionCodes';
import { redeemDriver } from '../../apollo/queries/drivers';
import { codeEntry } from '../../../config';
import { v1 as uuidv1 } from 'uuid';

/**
 * Login form component
 * @param {Object} loading - Is loading
 * @param {Object} onSubmit - callback
 * @returns {Object}
 */
export default function LoginForm(props) {
  const id = 'login-form';
  const { className, styles } = getStyles();
  const location = useLocation();
  const [loading, setLoading] = useState();
  const promoCodeDetails = location.state && location.state.promoCodeDetails ? location.state.promoCodeDetails : '';
  const [subscriptionId, setSubscriptionId] = useState();
  useEffect(() => {
    if (props.blok['redirect_url'] && props.blok['redirect_url'].url) {
      setSubscriptionId(uuidv1());
    }
  }, []);
  console.log(props.blok);
  const [hashEmail] = useMutation(getPartnerDriverEmail, {
    onCompleted(res) {
      if (res && res.getPartnerDriverEmail) {
        const emailAddress = res.getPartnerDriverEmail;
        const redirectUrl = `${props.blok['redirect_url'].url}${
          process.env.GATSBY_AWS_LAMBDA_HOST
        }&state=${JSON.stringify({ subscriptionId, emailAddress, action: 'login' })}`;
        window.open(redirectUrl, 'uber', 'width=600,height=300');
      } else {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', { state: { error: '' } });
      }
    },
    onError() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: '' } });
    },
  });

  const [loginDriver] = useMutation(redeemDriver, {
    onCompleted(res) {
      if (res && res.redeemDriver && res.redeemDriver.success) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (props.blok['confirmation_route'] && props.blok['confirmation_route'].url) {
          navigate(props.blok['confirmation_route'].url, { state: { action: 'login' } });
        } else {
          navigate('/confirmation', { state: { action: 'login' } });
        }
      } else {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', {
          state: { error: res.redeemDriver.errorCode || '', message: res.redeemDriver.message || '' },
        });
      }
    },
    onError(error) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: error.message } });
    },
  });

  const [onUpdateEnrollmentPlan] = useMutation(redeemPartnerDriver, {
    onCompleted(res) {
      if (res && res.redeemPartnerDriver && res.redeemPartnerDriver.success) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (props.blok['confirmation_route'] && props.blok['confirmation_route'].url) {
          navigate(props.blok['confirmation_route'].url, { state: { action: 'login' } });
        } else {
          navigate('/confirmation', { state: { action: 'login' } });
        }
      } else {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', {
          state: { error: res.redeemPartnerDriver.errorCode || '', message: res.redeemPartnerDriver.message || '' },
        });
      }
    },
    onError(error) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: error.message } });
    },
  });

  const initialValues = {
    email: '',
    password: '',
    subscribe: true,
    terms: false,
    subscriptionCopy: props.blok.subscriptionCopy,
  };
  const [applyPromotion] = useMutation(redeemPromotion, {
    onCompleted(res) {
      if (res && res.redeemPromotion && res.redeemPromotion.success) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (props.blok['confirmation_route'] && props.blok['confirmation_route'].url) {
          navigate(props.blok['confirmation_route'].url, { state: { action: 'login' } });
        } else {
          navigate('/confirmation', { state: { action: 'login' } });
        }
      } else {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', {
          state: { error: res.redeemPromotion.errorCode || '', message: res.redeemPromotion.message || '' },
        });
      }
    },
    onError(error) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: error.message } });
    },
  });

  const formRef = useRef();
  const {
    data: subscriptionData,
    error: subscriptionError,
    loading: subscriptionLoading,
  } = useSubscription(partnerDriverLinked, {
    variables: {
      input: {
        subscriptionId,
      },
    },
    skip: !subscriptionId,
  });

  useSubscription(partnerDriverNotLinked, {
    variables: {
      input: {
        subscriptionId,
      },
    },
    skip: !subscriptionId,
    onSubscriptionData: ({ subscriptionData }) => {
      const { data, error, loading } = subscriptionData;
      if (!loading && data) {
        const {
          partnerDriverNotLinked: { message },
        } = data;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', { state: { error: message, message } });
      }
      if (!loading && error) {
        const { message } = error;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigate('/error', { state: { error: message, message } });
      }
    },
  });

  useEffect(() => {
    if (!subscriptionLoading && subscriptionData) {
      const {
        partnerDriverLinked: { partnerDriverId },
      } = subscriptionData;
      handleSubmit({ ...formRef.current.values, partnerDriverId });
    }
  }, [subscriptionData, subscriptionLoading]);

  useEffect(() => {
    if (subscriptionError) {
      const { message } = subscriptionError;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      navigate('/error', { state: { error: message, message } });
    }
  }, [subscriptionError]);

  const handleSubmit = (values) => {
    const input =
      !_.isEmpty(promoCodeDetails) && codeEntry
        ? {
            promotionCode: promoCodeDetails.promotionCode,
            promotionRef: promoCodeDetails.promotionRef,
            planCode: props.blok['plan_code'],
            platformName: props.blok['platform_name'],
            ...values,
          }
        : {
            planCode: props.blok['plan_code'],
            platformName: props.blok['platform_name'],
            ...values,
          };
    const params = {
      variables: {
        input,
      },
    };
    if (props.blok['redirect_url'] && props.blok['redirect_url'].url) return onUpdateEnrollmentPlan(params);
    if (!_.isEmpty(promoCodeDetails))
      return applyPromotion({
        variables: {
          input: {
            address: null,
            city: null,
            email: values.email,
            firstName: null,
            lastName: null,
            make: null,
            model: null,
            password: values.password,
            phone: null,
            postalCode: null,
            subscribe: values.subscribe,
            state: null,
            terms: values.terms,
            year: null,
            subscriptionCopy: values.subscriptionCopy,
            promotionCode: promoCodeDetails.promotionCode,
            promotionRef: promoCodeDetails.promotionRef,
            planCode: props.blok['plan_code'],
            platformName: props.blok['platform_name'],
          },
        },
      });
    return loginDriver(params);
  };

  const handlePartnerLogin = ({ email }) => {
    return hashEmail({
      variables: {
        input: {
          email,
        },
      },
    });
  };

  const handleLogin = async (values) => {
    setLoading(true);
    return props.blok['redirect_url'] && props.blok['redirect_url'].url
      ? handlePartnerLogin(values)
      : handleSubmit(values);
  };

  return (
    <Fragment>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={handleLogin}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {(formik) => (
          <Form id={props.id} className={className}>
            <h3 className={className}>{props.blok.login_copy}</h3>
            <FormikTextInput id={`${id}-email`} className={className} name="email" label="Email" data-qa="email" />
            <FormikTextInput
              id={`${id}-password`}
              className={className}
              name="password"
              label="Password"
              type="password"
              data-qa="password"
            />
            <h6 className={className}>
              Minimum 8 chars: with at least 1 number, 1 uppercase, 1 lowercase
              &&nbsp;1&nbsp;special&nbsp;character&nbsp;(!,&nbsp;@,&nbsp;#&nbsp;...)
            </h6>
            <hr className={className} />
            {props.blok['terms_conditions_copy'] ? (
              <FormikCheckbox id={`${id}-terms`} className={className} name="terms" data-qa="terms-checkbox">
                <div clasName={className} dangerouslySetInnerHTML={{ __html: props.blok['terms_conditions_copy'] }} />
              </FormikCheckbox>
            ) : (
              <FormikCheckbox id={`${id}-terms`} className={className} name="terms" data-qa="terms-checkbox">
                By checking this box, you acknowledge you have read and agree to be bound by our{' '}
                <a target="_blank" className={className} href="https://www.evgo.com/terms-service/">
                  Terms of Service
                </a>{' '}
                and our{' '}
                <a target="_blank" className={className} href="https://www.evgo.com/privacy-policy/">
                  Privacy Policy
                </a>
                . By submitting this form, you acknowledge you are sharing your personal information with EVgo and
                consent to EVgo’s Privacy Notice for California Residents. More detail regarding personal information we
                collect, how we use that information, how we share that information, and your rights and choices can be
                found in our{' '}
                <a target="_blank" className={className} href="https://www.evgo.com/privacy-policy/">
                  Privacy Policy
                </a>
                .
              </FormikCheckbox>
            )}
            <FormikCheckbox id={`${id}-subscribe`} className={className} name="subscribe" data-qa="subscribe-checkbox">
              {props.blok.subscriptionCopy}
            </FormikCheckbox>

            {/* <div className={ className } style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <a className={ className } href="https://account.evgo.com/login/forgot" target="_blank">
                Forgot Password?
              </a>
            </div> */}

            <div className={`${className} submit-container`}>
              <button
                id={`${id}-submit-button`}
                className={className}
                type="submit"
                disabled={!!loading || !formik.isValid}
              >
                <span className={`${className} ${loading ? 'loading' : ''}`}>
                  {props.blok['login_submit_button_copy']}
                </span>
                {loading ? <Sync className={`${className} spinner`} /> : null}
              </button>
              <p className={className}>{props.blok['login_submit_button_subtext']}</p>
              {props.blok['disclaimer_text'] && (
                <>
                  <hr className={className} />
                  <p className={`${className} disclaimer-text`}>{props.blok['disclaimer_text']}</p>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>

      {styles}
    </Fragment>
  );
}
