import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
    section {
      background-color: white;
      box-sizing: border-box;
      flex: 0 0 auto;
      word-spacing: -.425px;
      padding: 32px 0px;
      width: 85%;
      color: #6f6f6f;
      font-size: 12px;
    }
  `;
};
