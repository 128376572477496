import React from 'react';
import getStyles from '../styles';
import SbEditable from "storyblok-react";

export default function Step(props) {
  const id = `confirmation-step-${props.index + 1}`;
  const { className, styles } = getStyles();
  return (
    <SbEditable content={ props.blok }>
      <li id={ `${id}` } className={ className }>
        <p id={ `${id}-header` } className={ `${className} step-header` }>{ props.blok.header }</p>
        <img id={ `${id}-image` } className={ `${className} step-image` } alt={ props.blok.image.alt } src={ props.blok.image.filename } />
        <p id={ `${id}-subtext` } className={ className }>{ props.blok.subtext }</p>
        {styles}
      </li>
    </SbEditable>
  );
}
