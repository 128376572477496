import { gql } from '@apollo/client';

export default gql`
  query listPortalsVehicleMakes {
    listPortalsVehicleMakes {
      value: altId
      text: manufacturer
    }
  }
`;
