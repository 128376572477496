import React, { useState } from 'react';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { Sync } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import validationSchema from './validation';
import FormikTextInput from '../shared/FormikTextInput';
import { navigate } from 'gatsby'

import getStyles from './styles';

import { validatePromoCode } from '../../apollo/queries/promotionCodes';
import SbEditable from "storyblok-react";

/**
 * Code Entry view component
 * @param {Object} props - React props
 * @returns {Object}
 */
export default function CodeEntryView(props) {
  const id = 'promo-code';

  const withBackgroundImage = !_.isEmpty(props.blok['background_image']) && !_.isEmpty(props.blok['background_image'].filename);
  const headerCentered = props.blok['header_centered'] ? 'centered' : '';
  const initialValues = { promotionCode: '' };

  const buttonStyles = {
    color: props.blok['button_color'],
    backgroundColor: props.blok['button_background_color'],
    rounded: !props.blok['button_square'],
  }
  const headerStyles = {
    color: props.blok['header_color'],
    marginTop: withBackgroundImage ? '-35vw !important' : 'auto',
  }
  const { className, styles } = getStyles(buttonStyles, headerStyles);

  const [error, setError] = useState(null);

  const [onValidatePromoCode, { loading }] = useLazyQuery(validatePromoCode, {
    onCompleted(res) {
      if (res && res.validatePromoCode && res.validatePromoCode.success) {
        let routeFound = false;
        if (props.blok['promocode_routes']) {
          props.blok['promocode_routes'].forEach((route) => {
            if (_.get(res, 'validatePromoCode.promotionCode', '').toUpperCase().substring(0, 2) === route['promocode_prefix'].toUpperCase()
                && !_.isEmpty(route['promocode_route'])) {
              routeFound = true;
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
              navigate(`/${route['promocode_route']}`,
                { state: { promoCodeDetails: { ...res.validatePromoCode } },
              });
            }
          });
        }
        if (props.blok['default_promocode_route'] && !routeFound) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          navigate(`/${props.blok['default_promocode_route']}`,
            { state: { promoCodeDetails: { ...res.validatePromoCode } },
          });
        } else if (!routeFound) {
          navigate('/error', { state: { error: 'Unknown', message: 'Error validating promo code, please try again.' } });
        }
      } else {
        navigate('/error', { state: { error: 'Unknown', message: 'Error validating promo code, please try again.' } });
      }
    },
    onError(err) {
      if (_.get(err, 'message', '').includes('combination was already redeemed')) {
        setError('This promo code was already redeemed.')
      } else {
        navigate('/error', { state: { error: _.get(err, 'code', 'Unknown'), message: _.get(err, 'message', 'Unknown') } });
      }
    },
  });

  return (
    <SbEditable content={props.blok}>
      <section id={ `${id}` } className={ className }>
        { !_.isEmpty(props.blok['background_image']) && !_.isEmpty(props.blok['background_image'].filename) && !_.isEmpty(props.blok['background_image'].alt) 
          && <img id={`${id}-background-image`} className={ className } src={ props.blok['background_image'].filename } alt={ props.blok['background_image'].alt } />
        }
        <div className={ `${className} header-container`}>
          { (!_.isEmpty(props.blok.header) || withBackgroundImage || !_.isEmpty(props.blok.subheader))
            && <header className={ `${className} ${withBackgroundImage ? 'withBackground' : ''} ${headerCentered}` }>
              { !_.isEmpty(props.blok.header)
                && <h1 id={`${id}-header`} className={ className }>{ props.blok.header }</h1>
              }
              { !_.isEmpty(props.blok.subheader)
                && <h4 id={`${id}-subheader`} className={ className }>{ props.blok.subheader }</h4>
              }
            </header>
          }
        </div>
        <Formik
          initialValues={ initialValues }
          onSubmit={ ({ promotionCode }) => {
            onValidatePromoCode({
              variables: {
                codeInput: {
                  promotionCode,
                  promotionRef: _.get(props, 'blok.promotion_ref', ''),
                },
              },
            });
          } }
          validationSchema={ validationSchema }
        >
          { ({ isValid, dirty }) => (
            <Form className={ `${className} ${withBackgroundImage ? 'withBackground' : ''}` }>
              { props.blok['promocode_header']
                && <p className={ className } style={{ color: props.blok['promocode_surrounding_text_color'] || '#000' }}>{ props.blok['promocode_header'] }</p>
              }
              <div className={ className }>
                <FormikTextInput id={ `${id}-input` } className={ className } error={ error } name="promotionCode" placeholder="Enter Code Here" type="promotionCode" />
                <button
                  id={ `${id}-submit` }
                  className={ className }
                  type="submit"
                  disabled={ !dirty || !isValid }>
                  <span className={ `${className} ${loading ? 'loading' : ''}` }>{ props.blok['button_text'] || 'Continue' }</span>
                  { loading ? <Sync className={`${className} spinner`} /> : null }
                </button>
              </div>
              { props.blok['promocode_subtext']
                && <p className={ className } style={{ color: props.blok['promocode_surrounding_text_color'] || '#000' }}>{ props.blok['promocode_subtext'] }</p>
              }
            </Form>
          ) }
        </Formik>
      </section>

      { styles }
    </SbEditable>
  );
}
