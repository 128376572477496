import React from 'react';
import { resolve } from 'styled-jsx/css';
import { brandColor, colors } from '../../../config';

export default (textColor) => {
  return resolve`
    section {
      width: 100%;
      color: ${textColor || 'inherit'};
      position: relative;
      text-align: center;

      header {
        width: 100%;
        position: absolute;
        h2 {
          font-size: 2em;
          font-weight: 700;
          padding: 0;
        }

        button {
          display: flex;
          align-items: center;
          align-content: center;
          height: 48px;
          margin: 1.5em auto 0;
          border-radius: 24px;
          padding: 0 24px;
          background-color: ${colors.accentYellow};
          color: ${brandColor};
          font-size: 1.25em;
          font-weight: 600;
          justify-content: center;
          text-decoration: none;
        }

        span {
          font-size: 1em;
          font-weight: 500;
          padding: 0;
        }
      }
      img {
        width: 100%;
      }
    }

    @media only screen and (min-width: 414px) {
      section {
        header {
          h2 {
            font-size: 3em;
          }

          span {
            font-size: 1.5em;
          }
        }
      }
    }

    @media only screen and (min-width: 640px) {
      section {
        header {
          h2 {
            font-size: 3.5em;
          }

          span {
            font-size: 2em;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      section {
        header {
          h2 {
            font-size: 4em;
          }

          span {
            font-size: 2.5em;
          }
        }
      }
    }

    @media only screen and (min-width: 980px) {
      section {
        &:not(.full-width){
          width: 95%;
        }
        margin: 0 auto;
      }
    }
  `;
};
