import React from 'react'
import SbEditable from 'storyblok-react'
import getStyles from './styles';


const SitemapLink = (props) => {
  const id = 'sitemap-link';
  const { className, styles } = getStyles();
  return (<SbEditable content={props.blok}>
    <li id={ `${id}` } className={`${ className } sitemap-item`}>
      <a
        id={ `${id}-link` }
        className={`${ className } sitemap-link`}
        href={ props.blok.link.url || '' }
        target="_blank"
        rel="noreferrer"
      >
        {props.blok.name}
      </a>
      { styles }
    </li>
  </SbEditable>);
}

export default SitemapLink
