import React from 'react';
import { resolve } from 'styled-jsx/css';
import { brandColor, colors } from '../../../../config';

export default (backgroundColor, ctaBackgroundColor, ctaTextUnderline) => {
  return resolve`
    section {
      display: flex;
      flex-direction: column;
      align-items: start;
      box-sizing: border-box;
      width: 100%;
      margin: 0 auto;
      background: ${backgroundColor || '#F0F5FF'};

      div#register-form-container {
        font-family: Muli,Roboto,Verdana,sans-serif !important;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        box-sizing: border-box;
        width: 100%;
        padding: 34px 48px;

        .header {
          text-align: center;
        }

        button {
          display: flex;
          align-items: center;
          align-content: center;
          height: 48px;
          margin-top: 1.5em;
          border-radius: 24px;
          padding: 0 24px;
          background: ${ctaBackgroundColor || colors.accentYellow};
          color: ${brandColor};
          font-size: 1.25em;
          font-weight: 500;
          justify-content: center;
          text-decoration: ${ctaTextUnderline ? 'underline': 'none'};
        }
        
        h2 {
          font-weight: 500;
          margin: 0;
          padding: 0;
        }
        
        hr {
          flex-basis: auto;
          width: 100%;
          margin: 2em 0;
        }
      
        ul {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 50px;
          font-size: 1.5em;
          list-style: none;

          li {
            position: relative;
            margin-top: 1em;
            padding-left: 30px;
            img {
              position: absolute;
              height: 20px;
              top: 3px;
              left: 0;
            }
          }
        }
      
        &:first-child {
          flex: 2 1 0%;
        }
        
        &:last-child {
          flex: 3;
        }
      }
    }

    @media only screen and (min-width: 980px) {
      section {
        &:not(.full-width) {
          width: 95%;
        }
        >div {
          .header{
            width: 125%;
          }
          &:first-child {
            margin: 0 auto;
            width: 65%;
          }
        }
      }
    }
  `;
};
