import React, { Fragment } from 'react';
import getStyles from './styles';
import SbEditable from 'storyblok-react';

/**
 * Two Photos layout component
 * @returns {Object}
 */
export default function TwoPhotos(props) {
  const id = 'two-photos';
  const { className, styles } = getStyles();

  return (
    <SbEditable content={props.blok}>
    <section id={ `${id}` } className={ className }>
      { props.blok['left_photo'] && props.blok['right_photo']
      && <Fragment>
        <img id={ `${id}-image-1` } className={ `${className} left-img` } alt={props.blok['left_photo'].alt} src={props.blok['left_photo'].filename} />
        <img id={ `${id}-image-2` } className={ `${className} right-img` } alt={props.blok['right_photo'].alt} src={props.blok['right_photo'].filename} />
      </Fragment>
      }
      { styles }
    </section>
    </SbEditable>
  );
}
