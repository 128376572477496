import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
    section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;
      margin-top: 10px;
      div {
        width: 75%;
        max-width: 250px;
        padding: 16px 16px 0;
        a {
          position: absolute;
          top: 0;
          right: 16px;
          font-weight: 800;
          text-decoration: none;
          font-size: 85%;
          margin-bottom: 5px;
        }
        &:last-child {
          justify-self: end;
          margin-top: 16px;
          width: 50%;
          max-width: 200px;
        }
      }
    }
    @media screen and (min-width: 980px) {
      section {
        width: 95%;

        div {
          padding: 16px 0px 0px;
          a {
            right: 0px;
          }
        }
      }
    }
  `;
};
