import React from 'react';
import { resolve } from 'styled-jsx/css';
import { brandColor, colors } from '../../../config';

export default (columns, enlargeImages, alignNumbersStart, fontWeight) => {
  return resolve`
    h2 {
      text-align: center;
      padding: 0;
      font-weight: ${fontWeight || 'auto'};
      font-size: 2em;
      margin: 48px auto;
      text-transform: uppercase;
      width: 85%;
    }

    ul {
      width: 85%;
      max-width: 984px;
      font-size: 1em;
      list-style: none;
      margin: 0 auto;
      font-weight: 500;

      li {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-bottom: 56px;

        img.main-image {
          height: ${enlargeImages ? 'auto' : '110px'};
          width: ${enlargeImages ? '100%' : 'auto'};
        }

        img.index-image {
          height: 30px;
          margin: 16px 0 0;
        }

        .index-bubble {
          height: 30px;
          width: 30px;
          background: #6B6B6B;
          color: white;
          display: flex;
          align-items: center;
          border-radius: 50%;
          margin-top: 20px;
          font-weight: bold;
        }

        p {
          padding: 15px;
        }

        .copy-container {
          flex-direction: ${alignNumbersStart ? 'row' : 'column'};
          text-align: ${alignNumbersStart ? 'left' : 'center'};
          align-items: ${alignNumbersStart ? 'start' : 'center'};
          align-self: ${alignNumbersStart ? 'start' : 'center'};
        }

        div {
          display: flex;
          justify-content: center;

          .app-icon {
            margin: 5px;
            display: block;

            img {
              height: auto;
              width: 100%;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      h2 {
        font-size: 2.5em;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(${columns}, 1fr);
        width: 85%;
        grid-gap: 32px;
        list-style: none;
        margin-bottom: 48px;
        li {
          img.index-image {
            margin-bottom: 16px;
          }
          p {
            padding: 15px;
          }
        }
      }
    }
  `;
};
