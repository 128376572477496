import React, { Fragment } from 'react';
import getStyles from './styles';
import SbEditable from 'storyblok-react';

/**
 * Partner Banner layout component
 * @returns {Object}
 */
export default function PartnerBanner(props) {
  const id = 'partner-banner';
  const { className, styles } = getStyles();
  return (
    <SbEditable content={props.blok}>
      <Fragment>
        <section id={ `${id}` } className={ className }>
            <a id={ `${id}-anchor` } className={ className } href="/" >
              <img id={ `${id}-image` } className={ className } alt={props.blok.partner_logo.alt} src={props.blok.partner_logo.filename} />
            </a>
        </section>
        { styles }
      </Fragment>
    </SbEditable>
  );
}
