import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
    div {
      position: relative;
      flex-basis: 100%;
      margin: 0 10px;

      label {
        display: flex;
        align-content: center;
        margin-top: 10px;
        color: #888888;
        cursor: pointer;
      }

      input {
        position: absolute;
        top: 10px;
        left: 0;
        opacity: 0;
      }

      svg {
        &.error {
          color: #CC0000;
        }
      }

      span {
        margin-left: 10px;
        font-size: 13px;
        color: #3f647c;
        &.error {
          margin-top: 10px;
          margin-left: 34px;
          color: #CC0000;
          font-weight: 400;
        }
      }
    }
  `;
};
