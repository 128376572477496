import React from 'react'
import SbEditable from 'storyblok-react'

const AppIcon = (props) => (
  <SbEditable content={props.blok}>
    <a id={`app-icon-${props.index}`} className={`${props.className || ''} app-icon`} href={ props.blok['app_icon_link'] } target="_blank">
      <img className={`${props.className || ''}`} src={ props.blok['app_icon_image'].filename } alt={ props.blok['app_icon_image'].alt }/>
    </a>
  </SbEditable>
)

export default AppIcon
