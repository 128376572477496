import { gql } from '@apollo/client';

/**
 * Partner Driver not linked subscription
 * @return {Promise<Object>}
 */
export default gql`
  subscription partnerDriverNotLinked($input: PartnerDriverLinkedInput!) {
    partnerDriverNotLinked(input: $input) {
      subscriptionId
      message
    }
  }
`;
