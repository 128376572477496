import React from 'react';
import { resolve } from 'styled-jsx/css';

export default (backgroundColor, fontWeight) => {
  return resolve`
    section {
      background: ${backgroundColor || ''};
      flex-direction: column;
      box-sizing: border-box;
      padding: 32px;
      width: 100%;

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 32px 0;
        text-align: center;
        
        h2 {
          margin: 0 0 16px;
          padding: 0;
          font-size: 2em;
          font-weight: ${fontWeight || 'auto'};
        }

        span {
          font-size: 1.2em;
        }
        
        img {
          max-height: 400px;
          max-width: 400px;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: dense;
        width: 95%;
        &.full-width {
          width: 100%;
        }

        > div {
          align-items: flex-start;
          text-align: left;
          padding: 32px;

          &.photo-first:first-child {
            grid-column: 2;
          }

          img {
            max-height: 300px;
            max-width: 350px;
          }
        }
      }
    }

    @media only screen and (min-width: 980px) {
      section {
        >div {
          h2 {
            font-size: 3.5em;
          }
          span {
            font-size: 1.4em;
          }
          img {
            max-height: 500px;
            max-width: 550px;
          }
        }
      }
    }
  `;
};
