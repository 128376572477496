import React from 'react';
import { resolve } from 'styled-jsx/css';
import { brandColor } from '../../../config';

export default () => {
  return resolve`
      .nav-item {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        list-style: none;
        padding: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        &.custom-padding {
          padding: 10px 0;
          a {
            padding-right: 10px;
          }
        }
        .nav-link {
          padding: 0 16px;
          flex: 0 1 auto;
          color: white;
        }
        &.no-underline {
          a {
          text-decoration: none;
          }
        }
        &.with-border {
          border-right: .5px solid ${brandColor};
          .nav-link {
            padding: 0;
          }
        }
        &.with-border:first-child {
          border-left: .5px solid ${brandColor};
        }
      }
      @media only screen and (min-width: 768px) {
      .nav-item.custom-padding {
          padding: 10px 20px;
        }
      }
  `;
};
