import React from 'react';
import { resolve } from 'styled-jsx/css';
import { brandColor, colors } from '../../../config';

export default () => {
  return resolve`
    button {
      display: flex;
      align-items: center;
      align-content: center;
      height: 48px;
      margin: 1.5em auto 0;
      border-radius: 24px;
      padding: 0 24px;
      background-color: ${colors.accentYellow};
      color: ${brandColor};
      font-size: 1.25em;
      font-weight: 600;
      justify-content: center;
      text-decoration: none;

      span {
        &.loading {
          opacity: 0;
        }
      }

      .spinner {
        position: absolute;
        animation: around 3s infinite;
      }

      .spinner::after {
        animation: around 0.7s ease-in-out 0.2s infinite;
        background: transparent;
      }

      @keyframes around {
        100% {
          transform: rotate(0deg)
        }
        0% {
          transform: rotate(1080deg)
        }
      }

      &[disabled] {
        opacity: 0.5;
        cursor: default;
      }
    }
    
    form {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;  
            
      h3 {
        font-weight: 700;
        padding-left: 10px;
        padding-bottom: 5px;
        padding-top: 10px;
        font-size: 1em;
        width: 100%;
      }
     
      h6 {
        flex-basis: 100%;
        padding: 0 10px;
        text-align: center;
        font-size: 13px;
        color: #3f647c;
      }
      
       hr {
        margin: 2em 0;
        border: 1px solid #AAAAAA;
        flex-basis: auto;
        width: 100%;
      }
      
      .disclaimer-text {
        color: #3f647c;
        font-size: 13px;
      }

      div.submit-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        p {
          padding: 16px;
        }
      }
      
      a {
        text-decoration: none;
      }
    }

    @media only screen and (min-width: 768px) {
      form {
        h3 {
          font-size: 1.2em;
        }
      }
    }
  `;
};
