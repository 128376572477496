import React from 'react';
import _ from 'lodash';
import getStyles from './styles';
import Components from '../components';

import SbEditable from "storyblok-react";
/**
 * Fast Charging component
 * @returns {Object}
 */
export default function MobileAppBanner(props) {
  const id = 'mobile-app-banner';
  const backgroundColor = props.blok['background_color'] ? props.blok['background_color']  : '';
  const textColor = props.blok['text_color'] ? props.blok['text_color']  : '';
  const { className, styles } = getStyles(backgroundColor, textColor);
  const photoFirst = props.blok['flip_order'] ? 'photo-first' : '';
  const fullWidth = props.blok['full_width'] ? 'full-width' : '';

  return (
    <SbEditable content={props.blok}>
    <section className={ `${className} ${fullWidth}` } id={ `${id}` }>
      <header className={ className }>
        <span className={ className } id={ `${id}-pre-header` }>{props.blok['pre_header']}</span>
        <h2 className={ className } id={ `${id}-header` }>{props.blok.header}</h2>
      </header>

      <div className={ `${className} ${photoFirst}` }>
        <div className={ className }>
          { props.blok['features_list']
          && !_.isEmpty(props.blok['features_list'])
            && <ul id={ `${id}-features-list` } className={className}>
              { props.blok['features_list'].map((blok, index) => React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                className,
                index,
                noIcon: true,
              }))}
            </ul>
          }
          { !_.isEmpty(props.blok['app_icons'])
            && <div className={ className }>
              { props.blok['app_icons'].map((blok, index) => React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                className,
                index,
              }))}
            </div>
          }
        </div>
        <img className={className} alt={props.blok.image.alt} src={props.blok.image.filename} id={ `${id}-image` } />
      </div>

      { styles }
    </section>
    </SbEditable>
  );
}
