import { gql } from '@apollo/client';

export default gql`
mutation redeemPromotion($input: EnrollPortalsDriverInput!) {
  redeemPromotion(input: $input) {
    accountNumber,
    activationUrl,
    emailAddress,
    planCode,
    platformName,
    success,
    contractCreated,
    welcomeNotificationSent,
    isSuccessful,
    action
  }
}
`;
