import React from 'react';
import BMWTypeNextTTThin from './fonts/BMWFonts/BMWTypeNextTT-Thin.ttf';
import BMWTypeNextTTLight from './fonts/BMWFonts/BMWTypeNextTT-Light.ttf';
import BMWTypeNextTTRegular from './fonts/BMWFonts/BMWTypeNextTT-Regular.ttf';
import BMWTypeNextTTBold from './fonts/BMWFonts/BMWTypeNextTT-Bold.ttf';
import MuliBlack from './fonts/Muli-Black.ttf';
import MuliBlackItalic from './fonts/Muli-BlackItalic.ttf';
import MuliBold from './fonts/Muli-Bold.ttf';
import MuliBoldItalic from './fonts/Muli-BoldItalic.ttf';
import MuliItalic from './fonts/Muli-Italic.ttf';
import MuliRegular from './fonts/Muli-Regular.ttf';
import MuliLight from './fonts/Muli-Light.ttf';
import MuliLightItalic from './fonts/Muli-LightItalic.ttf';
import MuliMedium from './fonts/Muli-Medium.ttf';
import MuliMediumItalic from './fonts/Muli-MediumItalic.ttf';
import RobotoThin from './fonts/Roboto-Thin.ttf';
import RobotoThinItalic from './fonts/Roboto-ThinItalic.ttf';
import RobotoLight from './fonts/Roboto-Light.ttf';
import RobotoLightItalic from './fonts/Roboto-LightItalic.ttf';
import RobotoRegular from './fonts/Roboto-Regular.ttf';
import RobotoMedium from './fonts/Roboto-Medium.ttf';
import RobotoMediumItalic from './fonts/Roboto-MediumItalic.ttf';
import RobotoItalic from './fonts/Roboto-Italic.ttf';
import RobotoBold from './fonts/Roboto-Bold.ttf';
import RobotoBoldItalic from './fonts/Roboto-BoldItalic.ttf';
import RobotoBlack from './fonts/Roboto-Black.ttf';
import RobotoBlackItalic from './fonts/Roboto-BlackItalic.ttf';
import _JSXStyle from "styled-jsx/style";

export default () => {

  return <style jsx global>{ `
    @font-face {
      font-family: 'BMWTypeNextTT';
      src: url('${BMWTypeNextTTThin}') format('truetype');
      font-weight: 600;
    }

    @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('${BMWTypeNextTTLight}') format('truetype');
        font-weight: 700;
    }

    @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('${BMWTypeNextTTRegular}') format('truetype');
        font-weight: 800;
    }

    @font-face {
        font-family: 'BMWTypeNextTT';
        src: url('${BMWTypeNextTTBold}') format('truetype');
        font-weight: 900;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoThin}') format('truetype');
        font-weight: 100;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoThinItalic}') format('truetype');
        font-weight: 100;
        font-style: italic;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoLight}') format('truetype');
        font-weight: 200;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoLightItalic}') format('truetype');
        font-weight: 200;
        font-style: italic;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoRegular}') format('truetype');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoItalic}') format('truetype');
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoMedium}') format('truetype');
        font-weight: 600;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoMediumItalic}') format('truetype');
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoBold}') format('truetype');
        font-weight: 800;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoBoldItalic}') format('truetype');
        font-weight: 800;
        font-style: italic;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoBlack}') format('truetype');
        font-weight: 900;
    }

    @font-face {
        font-family: 'Roboto';
        src: url('${RobotoBlackItalic}') format('truetype');
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliLight}') format('truetype');
        font-weight: 200;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliLightItalic}') format('truetype');
        font-weight: 200;
        font-style: italic;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliRegular}') format('truetype');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliItalic}') format('truetype');
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliMedium}') format('truetype');
        font-weight: 600;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliMediumItalic}') format('truetype');
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliBold}') format('truetype');
        font-weight: 800;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliBoldItalic}') format('truetype');
        font-weight: 800;
        font-style: italic;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliBlack}') format('truetype');
        font-weight: 900;
    }

    @font-face {
        font-family: 'Muli';
        src: url('${MuliBlackItalic}') format('truetype');
        font-weight: 900;
        font-style: italic;
    }

    :root {
      --safe-area-inset-top: env(safe-area-inset-top);
      --safe-area-inset-left: env(safe-area-inset-left);
      --safe-area-inset-right: env(safe-area-inset-right);
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
    }

    * {
      margin: 0px;
      outline: 0px;
      border: 0px;
      padding: 0px;
      overflow-anchor: none;
    }

    html,
    body,
    #root {
      overflow-x: hidden;
      height: 100%;
    }

    body,
    #root {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    body {
      background-color: #FFFFFF;
      font-family: Muli,Roboto,Verdana,sans-serif;
      color: #000000;
      font-size: 14px;
      line-height: normal;
      -webkit-overflow-scrolling: touch;
      text-underline-position: under;
    }
    
    #root {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
    }

    a {
      color: #00AEEB;
      text-decoration: none;
    }

     h1 {
        font-size: 2.25em;
        font-weight: 600;
        padding: 0 9%;
        padding-left: 9%;
      }

     h2 {
         font-size: 2em;
         font-weight: 700;
         padding: 48px 9% 0 9%;
     }

     h3 {
        font-weight: 600;
        font-size: 14pt;
        border-bottom: 0;
     }

     h4 {
       font-size: 1.25em;
       font-weight: 600;
       padding: 48px 9% 0;
     }

     h5 {
      font-size: 20px;
      font-weight: 200;
      line-height: 20px;
     }

     h6 {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: initial;
     }

     hr {
      flex-basis: 100%;
      margin: 20px 10px;
      border: 1px solid #AAAAAA;
    }
     ul {
      font-size: 12pt;
      margin-left: 19px;
      p {
        display: inline-block;
      }
    }

    #dropdown-options {
      margin: 0;
    }

    #legal-copy {
      p {
        display: inline-block;
      }
      ul {
        p {
          display: inline;
          font-size: 12px;
        }
      }
    }

    #faq {
      ul {
        p {
          display: inline;
        }
      }
    }

    button {
      padding: 20px 40px;
      font-size: 1.5em;
      font-weight: 600;
      text-align: center;
      font-family: inherit;
      cursor: pointer;
    }

    input {
      font-weight: 600;
      font-family: inherit;
    }

    section {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 10px;
      position: relative;
    }

    main.custom-font {
      font-family: BMWTypeNextTT,Muli,Roboto,Verdana,sans-serif;
      button {
        font-weight: 900;
      }
    }

    @media screen and (max-width: 425px) {
      h1 {
        font-size: 1.85em;
        font-weight: 600;
      }
      h2 {
        font-size: 1em;
      }
      h3 {
        font-size: 0.95em;
      }
      h4 {
        font-size: 1.25em;
        font-weight: 600;
        padding-bottom: 15px;
      }
      button {
        padding: 10px 20px 12px 20px;
        font-size: 0.9em;
      }
      ul {
        list-style-position: outside;
        margin-left: 5%;
      }
    }
  `}</style>;
};
