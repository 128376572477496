import { gql } from '@apollo/client';

export default gql`
  mutation redeemPartnerDriver($input: RedeemPartnerDriverInput!) {
    redeemPartnerDriver(input: $input) {
      action
      errorCode
      errorMessage
      message
      success
    }
  }
`;
