import * as Yup from 'yup';

export default Yup.object().shape({
  email: Yup.string()
    .strict(true)
    .trim('Email cannot include leading and trailing spaces')
    .email('Invalid email')
    .required('Required'),
  password: Yup.string().matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()\-_=+[\]{}\\|:;'",<.>/?])(?=.{8,})/,
    'Password requirements not met',
  ).required('Required'),
  subscribe: Yup.boolean(),
  terms: Yup.boolean().oneOf([true], 'Required'),
});
