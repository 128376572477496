import React from 'react';
import getStyles from './styles';
import { navigate } from 'gatsby';
import SbEditable from 'storyblok-react';

/**
 * Form Banner layout component
 * @returns {Object}
 */
export default function FormBanner(props) {
  const id = 'form-banner';
  const textColor = props.blok['text_color'] ? props.blok['text_color']  : '';
  const fullWidth = props.blok['full_width'] ? 'full-width' : '';
  const { className, styles } = getStyles(textColor);

  return (
    <SbEditable content={props.blok}>
    <section id={ `${id}` } className={ `${className} ${fullWidth}` }>
      <header className={ className }>
        <h2 id={ `${id}-header` } className={ className }>{ props.blok.header }</h2>
        <span id={ `${id}-subheader` } className={ className }>{ props.blok.subheader }</span>
        { props.blok['cta_button_link'] && props.blok['cta_button_link'].url && props.blok['cta_button_label']
          && <button id={ `${id}-cta-button` } onClick={ () => navigate(props.blok['cta_button_link'].url) } className={ className }>{ props.blok['cta_button_label'] }</button>
        }
      </header>
      <img id={ `${id}-image` } className={ className } alt={props.blok.image.alt} src={props.blok.image.filename} />
      { styles }
    </section>
    </SbEditable>
  );
}
