module.exports = {
  name: 'EVgo - Reliant Promotion ',
  shortName: 'Reliant Promotion',
  description: 'Register with EVgo and get hours of free charging on our network.',
  brandColor: '#002B39',
  apiHost: process.env.API_HOST || '//localhost:4000/',
  basename: process.env.BASENAME || '/',
  noscript: 'You need to enable JavaScript to run this application.',
  colors: {
    accentBlue: '#27657E',
    accentFaq: '#008ABD',
    accentLink: '#00AEEB',
    accentYellow: '#FFC425',
    bismarkBlue: '#426F85',
  },
  support: {
    phone: '877-494-3833',
    email: 'support@evgo.com',
  },
  vehicleInfo: {
    make: '',
    model: '',
    year: '',
  },
};
