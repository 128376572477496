import React from 'react';
import { resolve } from 'styled-jsx/css';
import { brandColor, colors } from '../../../config';

export default (backgroundColor) => {
  return resolve`
    section {
      margin: -10px 0 0;
    }
    div {
      width: 100%;
      margin: 0 auto;
      background: ${backgroundColor || colors.accentYellow};

      nav {
        display: flex;
        justify-content: center;
        width: 100%;
        font-weight: 800;
        font-size: 1em;
        padding: 15px 0;
        margin-left: -4px;
      }
    }
    @media only screen and (min-width: 768px) {
      div {
        nav {
          font-size: 1.5em;
          padding: 25px 0;
        }
      }
    }
  `;
};
