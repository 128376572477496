import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import SbEditable from 'storyblok-react';
import { useNavigate, useLocation } from '@reach/router';
import RegisterForm from '../../RegisterForm';
import LoginForm from '../../LoginForm';
import getStyles from './styles';
import Components from "../../components";

const RegisterView = (props) => {
  const id = 'register';
  const backgroundColor = props.blok['background_color'] || null;
  const ctaBackgroundColor = props.blok['header_cta_background_color'] || null;
  const ctaTextUnderline = props.blok['header_cta_text_underline'];
  const { className, styles } = getStyles(backgroundColor, ctaBackgroundColor, ctaTextUnderline);
  const [view, setView] = useState(id);
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fullWidth = props.blok['full_width'] ? 'full-width' : '';

  const promoCodeDetails = location.state && location.state.promoCodeDetails;

  useEffect(() => {
    if (props.blok.protectedRoute && !promoCodeDetails) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (location && location.hash && location.hash.includes('register') && view === 'login' && !clicked) {
      setView('register');
      setClicked(true);
    } else {
      setClicked(false);
    }
  }, [location, setView, view]);

  return (
    <SbEditable content={props.blok}>
    <section className={ `${className} ${fullWidth}` } id={ `${id}` }>
    { !_.isEmpty(props.blok.list_items)
      && <div className={ className }>
        <h2 className={ className }>{props.blok.list_title}</h2>
        <ul id={ `${id}-list` } className={className}>
          {props.blok.list_items && props.blok.list_items.map((blok) => React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            className,
          }))}
        </ul>

      </div>
    }
      <div id={ `${id}-form-container` } className={ className }>
        { view === 'register'
          ? <h2 className={ `${className} header`} dangerouslySetInnerHTML={{ __html: props.blok['register_header'] }} />
          : <h2 className={ `${className} header`} dangerouslySetInnerHTML={{ __html: props.blok['login_header'] }} />
        }
        <button
          id={ `${id}-cta-button` }
          className={ className }
          onClick={ () => {
            setClicked(true);
            if (view === 'register') {
              setView('login');
            } else if (view === 'login') {
              setView('register');
            }
          }}
        >
            { view === 'register' ? props.blok['register_header_cta_text'] : props.blok['login_header_cta_text'] }
        </button>
        <hr className={ className } />
        { view === 'login'
          ? <LoginForm className={ className } { ...props } />
          : <RegisterForm className={ className } { ...props } />
        }
      </div>
      {styles}
    </section>
    </SbEditable>
  );
}

export default RegisterView
