import React from 'react'
import Components from '../components.js';
import getStyles from './styles';

const StandAloneRouter = (props) => {
  const id = 'stand-alone-router';
  const { className, styles } = getStyles(props.blok['background_color']);
  return (
      <section id={`${id}`} className={className}>
        <div className={ className }>
          { props.blok.routes.length
            ? <nav id={ `${id}-routes-container` } className={ className }>
              { props.blok.routes && props.blok.routes.map((blok) => React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                noUnderline: true,
                customPadding: true,
              }))}
            </nav>
            : ''
          }
        </div>
        {styles}
      </section>
  )
}

export default StandAloneRouter
