import { gql } from '@apollo/client';

export default gql`
  mutation enrollPortalsDriver($input: EnrollPortalsDriverInput!) {
    enrollPortalsDriver(input: $input) {
      accountNumber
      activationUrl
      contractCreated
      success
      welcomeNotificationSent
    }
  }
`;
