import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
    div {
      position: relative;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 5px 10px 0;

      label {
        padding-bottom: 2px;
        font-size: 14px;
      }

      input {
        box-sizing: border-box;
        height: 48px;
        border: 2px solid rgba(0,0,0,0.25);
        border-radius: 4px;
        padding: 10px;
        color: #666666;
        font-size: 20px;
        cursor: pointer;

        &[disabled] {
          background-color: #CCCCCC;
          color: #AAAAAA;
        }
      }

      input.error {
        border: 2px solid red;
      }

      span {
        color: #CC0000;
        font-size: 14px;
      }

      ul {
        position: absolute;
        top: 88px;
        display: none;
        box-sizing: border-box;
        width: calc(100% - 20px);
        border-top: 1px solid #AAAAAA;
        background-color: white;
        height: calc(44px * 3);
        overflow-y: auto;
        list-style: none;

        li {
          box-sizing: border-box;
          padding: 10px 20px;
          color: #666666;
          font-size: 20px;
          cursor: pointer;

          &:hover {
            background-color: #CCCCCC;
          }
        }
      }

      &.open {
        z-index: 1000;

        ul {
          display: block;
        }

        .arrow {
          transform: rotate(-135deg);
        }
      }

      .arrow {
        position: absolute;
        top: calc(50% - 10px);
        right: 30px;
        display: inline-block;
        border: solid #666666;
        border-width: 0 4px 4px 0;
        padding: 4px;
        transform: rotate(45deg);
        transform-origin: 8.5px 8.5px;
        transition: all .2s ease;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 425px) {
      div {
        input {
          height: auto;
          font-size: 20px;
          line-height: 1.2em;
        }
        &::after {
          top: 18px;
          right: 30px;
        }
        &.open {
          &::after {
            transform: rotate(-135deg);
            top: 15px;
          }
        }
        ul {
          top: 48px;
          margin-left: 0;
        }
      }
    }

    @media only screen and (min-width: 480px) {
      div {
        &::after {
          right: 30px;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      div {
        input {
          padding: 10px 20px;
        }
        &.half {
          flex-basis: 50%;
        }

        &.quarter {
          flex-basis: 25%;
        }
      }
    }
  `;
};
