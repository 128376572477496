import React from 'react';
import SbEditable from 'storyblok-react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import getStyles from './styles';

/**
 * Error component
 * @param {Object} props - React props
 * @returns {Object}
 */
export default function Error(props) {
  const id = 'error';
  const textColor = props.blok['text_color'] || '';
  const fullWidth = props.blok['full_width'] ? 'full-width' : '';
  const withBackground = !!(props.blok['background_image'] && props.blok['background_image'].filename);
  const { className, styles } = getStyles(textColor, withBackground);
  let title = 'Something went wrong';
  let errorCode = '';
  const location = useLocation();

  let message = (
    <>
      Try going back and trying again. If the problem persists, contact&nbsp;

      <a className={ className } href="mailto:support@evgo.com">
        support@evgo.com
      </a>
    </>
  );
  const action = (
    <button className={ className } onClick={ () => navigate(-1) }>Continue</button>
  );

  // TO DO: Verify that we will no longer need this block and parse only
  // errors we are getting from portals-server
  if (location && location.state && location.state.error) {
    errorCode = location.state.error.includes(':') ? location.state.error : `Error: ${location.state.error}`;
    switch (location.state.error) {
      case 'promotion_code_not_found':
        errorCode = 'Invalid code. Please enter a valid code.';
        break;
      case 'promotion_code_redeemed':
        errorCode = 'Code has already been redeemed. Please enter a code that has not yet been redeemed.';
        break;
      case 'request_body':
        errorCode = `Error: ${location.state.message}`;
        break;
      case 'INVALID_LAST_FIRST_NAME':
        errorCode = 'Error: Email and password not found.';
        break;
      case 'not-found':
        title = 'Not Found';
        message = 'The page you are looking for does not exist. Please go back and try again.';
        errorCode = '';
        break;
      default:
        break;
    }
  }

  if (location && location.state && location.state.message) {
    const { state: { message } } = location;
    if (message.includes('combination is not found')) {
      errorCode = 'Invalid code. Please enter a valid code.';
    }
    if (message.includes('combination was already redeemed')) {
      errorCode = 'Code has already been redeemed. Please enter a code that has not yet been redeemed.';
    }
    if (message === 'UNEXPECTED_ERROR') {
      errorCode = 'Unexpected error. Please check your input and try again.';
    }
  }

  return (
    <SbEditable content={ props.blok }>
      <section id={ `${id}` } className={ `${className} ${fullWidth}` }>
        <header className={ className }>
          <h1 id={ `${id}-title` } className={ className }>{ props.blok.title || title }</h1>
          <p id={ `${id}-message` } className={ className }>{ props.blok.subtitle || message }</p>
          { action }
        </header>
        { withBackground
        && <img className={ className } src={ props.blok['background_image'].filename } alt={ props.blok['background_image'].alt || '' } />
        }
        <span id={ `${id}-error-code` } className={ className }>{ errorCode }</span>
        { styles }
      </section>
    </SbEditable>
  );
}
