import React, { useState } from 'react';
import Components from '../../components.js';
import { ExpandLess as ExpandLessIcon, ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

import appStore from '../../../images/app-store.png';
import evgoLogo from '../../../images/logo.svg';
import playStore from '../../../images/play-store.png';
import getStyles from './styles';

/**
 * Footer layout component
 * @returns {Object}
 */
export default function Footer(props) {
  const id = 'footer';
  const { className, styles } = getStyles();
  const [email, setEmail] = useState('');

  return (
    <footer id={`${id}`} className={className}>
      <section className={className}>
        <div className={className}>
          <img id={`${id}-logo`} className={className} src={evgoLogo} />
          <br />
          <span className={className}>
            Sign up for the EVgo mailing list, so you can keep in touch with updated charging locations, charging news
            and more.
          </span>

          <form
            action="https://evgo.us13.list-manage.com/subscribe/post?u=dcd940242b9acc87b6b2d4da5&amp;id=3f34fcf053"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className={className}
            target="_blank"
            noValidate=""
          >
            <div id="mc_embed_signup_scroll" className={className}>
              <label className={className}>
                <input
                  type="email"
                  value={email}
                  name="EMAIL"
                  className={className}
                  id="mce-EMAIL"
                  placeholder="Email Address"
                  required=""
                  onChange={({ target: { value = '' } = {} }) => setEmail(value)}
                />
                <input
                  aria-hidden="true"
                  type="text"
                  name="b_dcd940242b9acc87b6b2d4da5_3f34fcf053"
                  tabindex="-1"
                  value=""
                  className={className}
                />
              </label>

              <button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={className}>
                <ArrowForwardIcon />
              </button>
            </div>
          </form>
        </div>
        {props.blok.sitemaps &&
          props.blok.sitemaps.map((blok) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            }),
          )}

        <div className={className}>
          <a
            id={`${id}-appstore-link`}
            className={className}
            href="https://apps.apple.com/us/app/evgo/id1281660968"
            target="_blank"
            rel="noreferrer"
          >
            <img className={className} src={appStore} id="footer-appstore-image" />
          </a>
          <a
            id={`${id}-playstore-link`}
            href="https://play.google.com/store/apps/details?id=com.driivz.mobile.android.evgo.driver"
            target="_blank"
            rel="noreferrer"
          >
            <img className={className} src={playStore} id="footer-playstore-image" />
          </a>
        </div>
      </section>

      <hr className={className} />

      <section className={className}>
        <span id={`${id}-copyright`} className={className}>
          Copyright &copy; 2020 EVgo Services LLC. All Rights Reserved.
        </span>

        <span className={className}>
          <a
            id={`${id}-privacy-policy-link`}
            className={className}
            href="https://www.evgo.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <a
            id={`${id}-terms-service-link`}
            className={className}
            href="https://www.evgo.com/terms-of-service/"
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
        </span>

        <div className={`social ${className}`}>
          <a
            id={`${id}-instagram-link`}
            className={className}
            href="https://www.instagram.com/evgonetwork/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            id={`${id}-twitter-link`}
            className={className}
            href="https://www.twitter.com/evgonetwork"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            id={`${id}-youtube-link`}
            className={className}
            href="https://www.youtube.com/user/evgonetwork"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a
            id={`${id}-linkedin-link`}
            className={className}
            href="https://www.linkedin.com/company/evgo/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a
            id={`${id}-facebook-link`}
            className={className}
            href="https://www.facebook.com/evgonetwork"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            id={`${id}-to-header`}
            className={className}
            href="#"
            onClick={() => {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }}
          >
            <ExpandLessIcon />
          </a>
        </div>
      </section>

      {styles}
    </footer>
  );
}
