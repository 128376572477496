import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
      .sitemap-item {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        margin: 4px 0;
        list-style: none;
      }
      .sitemap-link {
        flex: 0 1 auto;
        color: white;
        text-decoration: none;
      }
  `;
};
