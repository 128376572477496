import React from 'react';
import { resolve } from 'styled-jsx/css';

import { brandColor, colors } from '../../../config';

export default () => {
  return resolve`
    section {
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
      text-align: center;
      color: ${brandColor};

      .header-container {
        width: 100%;
        margin: 0 auto;
        align-items: center;

        header {
          width: 100%;
          position: absolute;
          h2 {
            font-size: 2em;
            font-weight: 700;
            padding: 0;
          }

          span {
            font-size: 1em;
            font-weight: 500;
            padding: 0;
          }
        }
        img {
          width: 100%;
        }
      }

      button,
      .button {
        display: flex;
        align-items: center;
        align-content: center;
        height: 48px;
        margin: 2em 0;
        border-radius: 24px;
        padding: 0 24px;
        background-color: ${colors.accentYellow};
        color: ${brandColor};
        font-size: 1.25em;
        font-weight: 600;
        justify-content: center;
        text-decoration: none;
      }

      h1 {
        font-size: 2.75em;
        font-weight: 700;
        margin-top: 48px;
      }

      > div {
        display: flex;
        margin: 2em auto 0;
        width: 70%;

        ol {
          li {
            text-align: left;
            margin-bottom: 48px;
          }
        }

        img.step-image {
          max-width: 95%;
          max-height: 310px;
        }

        div:second-child {
          margin-right: 10%;
        }
      }

      p {
        margin: 8px 0 24px;
        width: 95%;
      }
    }

    @media only screen and (min-width: 414px) {
      section {
        .header-container {
          header {
            h2 {
              font-size: 3em;
            }

            span {
              font-size: 1.5em;
            }
          }
        }
      }
    }


    @media only screen and (min-width: 480px) {
      section {
        > div {
          width: auto;
          align-items: center;
        }
      }
    }

    @media only screen and (min-width: 640px) {
      section {
        .header-container {
          header {
            h2 {
              font-size: 3.5em;
            }

            span {
              font-size: 2em;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      section {
        .header-container {
          header {
            h2 {
              font-size: 4em;
            }

            span {
              font-size: 2.5em;
            }
          }
        }
      }
    }


    @media only screen and (min-width: 980px) {
      section {

        > div:not(.header-container) {
          margin: 2em auto;
          width: 100%;
          justify-content: center;

          ol {
            display: flex;
            li {
              margin: 0 24px 0;
            }
            li::marker {
              font-size: 1.5em;
            }
          }

          p.step-header {
            font-size: 1.5em;
            width: 70%;
          }
        }
      }
    }
  `;
};
