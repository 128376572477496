import { gql } from '@apollo/client';

export default gql`
  mutation redeemDriver($input: RedeemDriverInput!) {
    redeemDriver(input: $input) {
    accountNumber,
    activationUrl,
    emailAddress,
    planCode,
    platformName,
    success,
    contractCreated,
    welcomeNotificationSent,
    isSuccessful,
    action
    }
  }
`;
