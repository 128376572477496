import React from 'react'
import SbEditable from 'storyblok-react'
import getStyles from './styles';


const NavItem = (props) => {
  const id = 'nav-item'
  const { className, styles } = getStyles();
  return (<SbEditable content={props.blok}>
    <li
      id={ `${id}` }
      className={`${ className }
      nav-item
      ${props.withBorder ? 'with-border' : ''}
      ${props.noUnderline ? 'no-underline' : ''}
      ${props.customPadding ? 'custom-padding' : ''}`}>
      <a
        id={ `${id}-link` }
        className={`${ className } nav-link`}
        style={{ 'color': props.blok.color }}
        href={ props.blok.link.cached_url === '/home' ? '' : props.blok.link.cached_url }
      >
        {props.blok.name}
      </a>
      { styles }
    </li>
  </SbEditable>);
}

export default NavItem
