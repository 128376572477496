import React from 'react';
import { resolve } from 'styled-jsx/css';
import { brandColor, colors } from '../../../config';

export default () => {
  return resolve`
    section {
      margin: 0;
    }
    div {
      width: 100%;
      margin: 0 auto;
      background: ${colors.accentYellow};

      nav {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    @media only screen and (min-width: 980px) {
      div {
        width: 95%;
      }
    }
  `;
};
