import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
    section {
      flex-direction: column;
      width: 95%;
      img {
        padding: 32px;
        max-width: 75%;
        &.left-img {
          max-width: 50%;
          margin: 0 auto;
        }
      }
    }
    @media only screen and (min-width: 980px) {
      section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        img {
          padding: 32px;
          max-width: 500px;
          &.left-img {
            max-width: 300px;
            margin: 0 auto;
          }
        }
      }
    }
  `;
};
