import React from 'react'
import SbEditable from 'storyblok-react'
import getStyles from './styles';

const Teaser = (props) => {
  const id = 'teaser';
  const { className, styles } = getStyles();
  return (
    <SbEditable content={props.blok}>
      <div className={ className } id={ `${id}` }>
        <div className={className}>
          <img id={ `${id}-image` } className={className} alt={props.blok.banner.alt} src={props.blok.banner.filename}/>
        </div>
        <section className={ className }>
          <h1 id={ `${id}-headline` } className={ className }>{props.blok.headline}</h1>
          <h4 id={ `${id}-description` } className={ className }>{props.blok.description}</h4>
        </section>
        {styles}
      </div>
    </SbEditable>
  );
}

export default Teaser
