import React from 'react'
import SbEditable from 'storyblok-react'

const FAQQuestion = (props) => (
  <SbEditable content={props.blok}>
    <li className={ props.className || '' }>
      <h3 id={ `${props.id}-question-${props.index}` } className={ props.className || '' }>{ props.blok.question }</h3>
      <div id={ `${props.id}-answer-${props.index}` } className={ props.className || '' } dangerouslySetInnerHTML={ { __html: props.storyblokClient.richTextResolver.render(props.blok.answer)} } />
    </li>
  </SbEditable>
);

export default FAQQuestion
