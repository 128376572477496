import React from 'react';
import { resolve } from 'styled-jsx/css';

export default (backgroundColor, textColor) => {
  return resolve`
    section {
      background: ${backgroundColor || ''};
      color: ${textColor || 'inherit'};
      flex-direction: column;
      box-sizing: border-box;
      padding: 32px 24px 0;
      width: 100%;

      header {
        text-align: center;

        h2 {
          margin-bottom: 16px;
          padding: 0;
          font-size: 2em;
        }

        span {
          font-size: 1em;
        }
      }

      >div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-top: 16px;
        max-width: 300px;

        >div {
          div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            justify-items: center;
            margin-bottom: 32px;
            width: 100%;
            max-width: 300px;
            a {
              cursor: pointer;
              width: 85%;
              img {
                width: 100%;
              }
            }
          }
        }

        ul {
          font-size: 1.4em;
          margin-bottom: 16px;
          li {
            p {
              display: inline;
            }
          }
        }
        
        img#mobile-app-banner-image {
          max-width: 300px;
          justify-self: center;
        }
      }
    }

    @media only screen and (min-width: 622px) {
      section {
        header {
          h2 {
            font-size: 3em;
          }

          span {
            font-size: 1.2em;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      section {
        width: 95%;
        &.full-width {
          width: 100%;
        }

        > div {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 48px;
          grid-auto-flow: dense;
          align-items: start;
          max-width: 100%;

          >div {
            div {
              justify-items: start;
            }
          }

          &.photo-first>div:first-child {
            grid-column: 2;
          }

          ul {
            font-size: 1.5em;
            margin-bottom: 24px;
          }

          img#mobile-app-banner-image {
            max-width: 300px;
          }
        }
      }
    }

    @media only screen and (min-width: 980px) {
      section {
        header {
          h2 {
            font-size: 3.5em;
          }

          span {
            font-size: 1.5em;
          }
        }
        >div {
          ul {
            font-size: 2em;
          }

          img#mobile-app-banner-image {
            max-width: 400px;
            padding: 0 48px;
          }
        }
      }
    }
  `;
};
