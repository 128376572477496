import React from "react";
import SbEditable from "storyblok-react";
import { DynamicWidget } from "@evgo/pricing-rates-web";
import "@evgo/pricing-rates-web/dist/index.css";
import styles from "./styles.module.css";

const PricingWidget = (props) => {
  const widgetType = props.blok.widget_type;
  return (
    <SbEditable content={props.blok}>
      <section
        id={props.blok["internal_link"] || ""}
        className={styles.pricingWidgetContainer}
      >
        <DynamicWidget type={widgetType} />
      </section>
    </SbEditable>
  );
};

export default PricingWidget;
