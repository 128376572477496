import React from 'react';
import { resolve } from 'styled-jsx/css';

export default () => {
  return resolve`
  img {
    width: auto;
    max-width: 100%;
  }

  div {
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  section {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 64px 0 0;
      background-size: cover;
      color: rgb(0, 43, 57);

      h1 {
        font-size: 2.5em;
        font-weight: 700;
        padding: 0;
        text-align: center;
        max-width: 69%;
      }

      h4 {
        font-size: 1.25em;
        font-weight: 500;
        padding: 18px;
        text-align: center;

        &.header {
          margin: 0 160px;
        }
      }

      form {
        display: flex;
        width: 50%;
        margin-top: 32px;

        div {
          width: 100%;
          padding: 0;

          div {
            label {
              padding: 0;
            }

            span {
              position: fixed;

              &.error {
                color: #FFADAD;
              }
            }
          }
        }
        img {
          width: 100%;
          margin: 0 auto;
        }
        button {
          display: flex;
          align-items: center;
          align-content: center;
          height: 48px;
          margin-left: 16px;
          border-radius: 24px;
          padding: 0 48px;
          background-color: rgb(255, 196, 37);
          color: rgb(0, 43, 57);
          justify-content: center;
          text-decoration: none;

          span {
            &.loading {
              opacity: 0;
            }
          }

          .spinner {
            position: absolute;
            animation: around 3s infinite;
          }

          .spinner::after {
            animation: around 0.7s ease-in-out 0.2s infinite;
            background: transparent;
          }

          @keyframes around {
            100% {
              transform: rotate(0deg)
            }
            0% {
              transform: rotate(1080deg)
            }
          }

          &[disabled] {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }

    @media only screen and (min-width: 980px) {
      img {
        width: auto;
        max-width: 95%;
      }
      section {
        h1 {
          font-size: 3.5em;
        }

        h4 {
          font-size: 1.5em;
        }
      }
    }
  `;
};
