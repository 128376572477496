import React from 'react';
import { useField } from 'formik';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

import getStyles from './styles';

export default function FormikCheckbox(props) {
  const id = 'checkbox';
  const { className, styles } = getStyles();
  const { children, className: inputClass, ...fieldProps } = props;
  const [field, meta] = useField({ ...fieldProps, type: 'checkbox' });
  const checkboxClass = `${className} ${(meta.touched && meta.error) ? 'error' : ''}`;
  let customClass = className;
  let checkbox = <CheckBoxOutlineBlank className={ checkboxClass } />;

  if (inputClass) customClass = `${inputClass} ${className}`;
  if (field.checked) checkbox = <CheckBox className={ checkboxClass } />;

  return (
    <div id={ `${id}-container` } className={ customClass }>
      <label id={ `${id}-label` } className={ className }>
        <input id={ `${id}` } className={ className } type="checkbox" { ...field } { ...fieldProps } />
        { checkbox }
        <span id={ `${id}-children-container` }  className={ className }>
          { children }
        </span>
      </label>
      { (meta.touched && meta.error) ? (
        <span id={ `${id}-error` } className={ `${className} error` }>{ meta.error }</span>
      ) : null }

      { styles }
    </div>
  );
}
