import React, { Fragment } from 'react'
import Components from '../../components';
import getStyles from './styles';
import SbEditable from "storyblok-react";

const List = (props) => {
  const { className, styles } = getStyles();
  return (
    <SbEditable content={props.blok}>
      <section className={className}>
        <h2 className={ className }>{props.blok.list_title}</h2>
        <ul className={ className } id="list">
          {props.blok.list_items && props.blok.list_items.map((blok, index) => React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            className,
            index,
          }))}
        </ul>
        { styles }
      </section>
    </SbEditable>
  )
}

export default List
